////////// VIEWPORT WITH 3D MODEL ///////////
import React, { useRef, useEffect, Suspense } from "react"
import { Canvas, useThree, useLoader } from "@react-three/fiber"
import { ContactShadows, useGLTF, OrbitControls, useTexture } from "@react-three/drei"
import { proxy, useSnapshot } from "valtio"
import Loading from "./gui/LoadingThree.js"
import IdSpezification, {
  getAttribute,
  changevalue,
} from "./spezifications/Spezification_Functions.js"
import * as THREE from "three"
import { ResizeObserver } from '@juggle/resize-observer';

import Balg from "./Viewport/Balg.js"
import Balgrahmen from "./Viewport/Balgrahmen.js"
import Base from "./Viewport/Base.js"
import Bassseite from "./Viewport/Bassseite.js"
import Beschlaege from "./Viewport/Beschlaege.js"
import Griff_Melodieseite from "./Viewport/Griff_Melodieseite.js"
import Intarsien from "./Viewport/Intarsien.js"
import Loecher from "./Viewport/Loecher.js"
import Namenstafel from "./Viewport/Namenstafel.js"
import Roeteln from "./Viewport/Roeteln.js"
import Verdeck_Melodieseite from "./Viewport/Verdeck_Melodieseite.js"
import Verdeck_Bassseite from "./Viewport/Verdeck_Bassseite.js"
import Bassseite_Griffbrett from "./Viewport/Bassseite_Griffbrett.js"



function Environment(props) {
  const { scene } = useThree()
  const texture = useLoader(THREE.TextureLoader, props.image)
  texture.mapping = THREE.EquirectangularReflectionMapping
  texture.encoding = THREE.sRGBEncoding
  scene.environment = texture
  return null
}

// Using a Valtio state model to bridge reactivity between
// the canvas and the dom, both can write to it and/or react to it.
const state = proxy({
  current: null,
  mouseopacity: 0.0,
  items: {
    white: "#ffffff",
    brown: "#af968a", 
    green: "#00ff00",
    metal: "#999999",
    Modell: "EGGI 2",
    Knoepfe: "Teflon rund",
    Roeteln: "ohne Röteln",
    Verdeck: "Typ Nussbaumer",
    Beschlaege: "Typ Ott",
    VerdeckGravur: "images/textures/verdeck/gravur_edelweiss_alpha.jpg",
    BalgfaltenKunstleder: "#552d2e",
    BalgLederecken: "#80222e",
    BalgrahmenKunstleder: "#552d2e",
    Holz: "images/textures/holz/kirschbaum.jpg",
    Holz02: "images/textures/holz/kirschbaum.jpg",
    Griffbrett: "images/textures/griffbrett/kunstharz_naturfaser_braun.jpg",
    Balgpapier: "images/textures/balgpapier/tassotti_kornblumen.jpg",
    Intarsien: "images/textures/intarsien/karo_ahorn_9.jpg",
    IntarsienAlpha: "images/textures/intarsien/alpha/karo_ahorn_9_alpha.jpg",
    Balgrahmen: "images/textures/balgrahmen/laser_eidgenuss.jpg",
    BalgrahmenAlpha: "images/textures/balgrahmen/alpha/laser_eidgenuss_alpha.jpg",
    BalgrahmenIsPapierband: true,
    StreifenIntarsien: false,
    HolzIsSchwarznuss: false,
  },
})





//////// das Oergeli und alle Materialien werden hier geladen ////////
function Oergeli(props) {
  useEffect(() => {
    //////// wenn das Oergeli geladen wird, wird das Icon eingeblendet
    if (!props.dontActivateIcon) {
      state.mouseopacity = 1.0
    }
  }, []) 

  const snap = useSnapshot(state)

  
//Oergeli_Griff_Melodieseite
  return (
    <>
      <Balg snap={snap} />
      <Balgrahmen snap={snap}/>
      <Base snap={snap} />
      <Bassseite snap={snap} />
      <Beschlaege snap={snap} />
      <Intarsien snap={snap} />
      <Namenstafel snap={snap} />
      <Roeteln snap={snap} />
      <Verdeck_Bassseite snap={snap} />
      <Verdeck_Melodieseite snap={snap}/>
      <Loecher snap={snap} />
      <Bassseite_Griffbrett snap={snap} />
      <Griff_Melodieseite snap={snap} />

    </>
  )
}



//////// function to change textures ////////
export function changeTexture(title, value, materialname, texture, hasalpha, alphamap) {
  if (hasalpha) {
    state.items[materialname + "Alpha"] = alphamap
  }

  changevalue(title, value)
  state.items[materialname] = texture
  if (title === "Holz") {
    state.items.Holz02 = getAttribute("Holz", "list")[value - 1].Holz2

    if (getAttribute("Holz", "specification").includes("Schwarznuss")&&!getAttribute("Holz", "specification").includes("Ahorn")) {
      state.items.HolzIsSchwarznuss = true
    } else {
      state.items.HolzIsSchwarznuss = false
    }


    if (getAttribute("Balgrahmen", "specification").includes("Laser")) {
      state.items.Balgrahmen = getAttribute("Holz", "list")[value - 1].laseredHolz
    }
  }
  


  if (title === "Balgrahmen") {
    if (getAttribute("Balgrahmen", "specification").includes("Papierband")) {
      state.items.BalgrahmenIsPapierband = true
    } else {
      state.items.BalgrahmenIsPapierband = false
      if(state.items.Beschlaege == "Typ Nussbaumer"){
        changeText("Beschläge", 1, "Beschlaege", "Typ Ott")
      }
    }
    if(getAttribute("Balgrahmen", "specification").includes("Streifen")&&!getAttribute("Modell", "specification").includes(" GS")){
      state.items.StreifenIntarsien = true
    } else {
      state.items.StreifenIntarsien = false
    }

    if (getAttribute("Balgrahmen", "specification").includes("Laser")) {
      state.items.Balgrahmen = getAttribute("Holz", "list")[
        getAttribute("Holz", "value") - 1
      ].laseredHolz
    }
  }
}

//////// function to change the color ////////
export function changeColor(title, value, materialname, color) {
  state.items[materialname] = color
  changevalue(title, value)
}

//////// funciton to change the text ////////
export function changeText(title, value, materialname, text) {
  try{
    state.items[materialname] = text
  }catch(erro){
    console.log("Error: Viewport - changeText: "+error)
  }
  changevalue(title, value)
}

//////// depending on the type it does change other attributes ////////
function updateOergeli() {
  for (var i = 0; i < IdSpezification.length; i++) {
    if (IdSpezification[i].type === "color") {
      changeColor(
        IdSpezification[i].title,
        IdSpezification[i].value,
        IdSpezification[i].materialname,
        IdSpezification[i].color,
      )
    } else if (IdSpezification[i].type === "texture") {
      changeTexture(
        IdSpezification[i].title,
        IdSpezification[i].value,
        IdSpezification[i].materialname,
        IdSpezification[i].texture,
        false,
      )
    } else if (IdSpezification[i].type === "alphatexture") {
      changeTexture(
        IdSpezification[i].title,
        IdSpezification[i].value,
        IdSpezification[i].materialname,
        IdSpezification[i].texture,
        true,
        getAttribute(IdSpezification[i].title, "alpha"),
      )
    } else if (IdSpezification[i].type === "text") {
      changeText(
        IdSpezification[i].title,
        IdSpezification[i].value,
        IdSpezification[i].materialname,
        IdSpezification[i].specification,
      )
    }
  }
}

//////// The Icon gets defined ////////
function MoveIcon(props) {
  const snap = useSnapshot(state)
  return (
    <>
      <img
        src="images/icons/hand_left_right.png"
        alt="rotate3d"
        id="rotate3d"
        style={{ opacity: snap.mouseopacity }}
      />
    </>
  )
}

//////// hide the Icon ////////
function hideCursor() {
  state.mouseopacity = 0.0
}

//////// when the Controls are available the  Icon gets displayed ////////
function Controls(props) {
  const ref = useRef()
  const { camera, gl } = useThree()
  useEffect(() => {
    ref.current.addEventListener("change", hideCursor)
    //return () => ref.current.removeEventListener("change", hideCursor)
  }, [])

  return (
    <OrbitControls
      ref={ref}
      args={[camera, gl.domElement]}
      minDistance={1.7}
      maxDistance={9}
      minPolarAngle={(Math.PI / 16) * 1}
      maxPolarAngle={(Math.PI / 16) * 8.5}
      enableZoom={true}
      enablePan={false}
    />
  )
}

//////// the content of the viewport gets defined ////////
export default function App(props) {
  useEffect(() => {
    updateOergeli()
  }, [])

  const backgroundcolor = props.background

  return (
    <>
      <Canvas
        id={props.id}
        onPointerDown={(e) => alert("click")}
        shadows
        frameloop="demand"
        colorManagement
        dpr={[1, 2]}
        camera={{ position: [3.75, 1.8, 0], fov: props.fov }}
        gl={{
          preserveDrawingBuffer: props.preserveDrawingBuffer,
          antialias: true,
        }}
        resize={{ polyfill: ResizeObserver }}
      >
        <color attach="background" args={backgroundcolor} />
        <Suspense fallback={<Loading />}>
          <Oergeli dontActivateIcon={props.noIcon} scale={1}/>
          <ContactShadows
            rotation-x={Math.PI / 2}
            position={[0, -0.54, 0]}
            opacity={1.35}
            width={15}
            height={10}
            blur={1.5}
            far={0.8}
          />
          <Environment image="./images/studio_10.jpg" />
        </Suspense>
        <spotLight intensity={1.5} angle={0.1} penumbra={1} position={[3, 3, 8]} castShadow />
        <spotLight intensity={0.4} angle={0.1} penumbra={1} position={[1, 0, -12]} castShadow />
        <spotLight intensity={2} angle={0.1} penumbra={1} position={[-30, 25, 0]} castShadow />
        <spotLight
          color={"#8ca7c2"}
          intensity={2}
          angle={0.1}
          penumbra={1}
          position={[15, -20, 10]}
          castShadow
        />
        <Controls />
      </Canvas>

      <MoveIcon opacity={state.mouseopacity} />
    </>
  )
}
