import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"

export default function Roeteln(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Roeteln.glb")
  
    if(snap.items.Roeteln.includes("mit Röteln")){
      return (
        <>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Roeteln} material-color={"#db2d2f"}/>
        </>
      )
    }else{
      return (
        <>
        </>
      )
    }
  
  }