/////////// CONTACT FORM ///////////
import React, { useRef, Suspense } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from "formik"
import countryList from "react-select-country-list"
import * as Yup from "yup"
import UserData from "./spezifications/User_Contactinfo.js"

export default function Form02() {
  const buttonRef = React.useRef({})

  return (
    <>
      <div id="main_form_upper">
        <div className="form_container">
          <div className="form_width">
            <div className="form_segment">
              <Suspense fallback={null}>
                <Formular buttonRef={buttonRef} />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <div id="main_form_under">
        <div className="navigation_arrow">
          <Link key="02" to="/02">
            <img src="images/icons/arrow_left.png" alt="arrow_left" className="arrow_left" />
          </Link>
          <img
            src="images/icons/arrow_right.png"
            alt="arrow_right"
            className="arrow_right"
            onClick={() => buttonRef.current()}
          />
        </div>
      </div>
    </>
  )
}
//onClick={handleSubmit}

/*Formular.defaultProps = {
  buttonRef: { current: {} },
}*/

function Formular({ buttonRef }) {
  React.useEffect(() => {
    buttonRef.current = handleSubmit
  }, [])

  // Attach this to your <Formik>
  const formRef = useRef()

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  let navigate = useNavigate()

  const SignupSchema = Yup.object().shape({
    salutation: Yup.string(),
    firstname: Yup.string().required("Bitte Vorname ausfüllen"),
    lastname: Yup.string().required("Bitte Nachname ausfüllen"),
    street: Yup.string().required("Bitte Strasse ausfüllen"),
    streetnr: Yup.string().required("Bitte Nr ausfüllen"),
    plz: Yup.string().required("Bitte PLZ ausfüllen"),
    place: Yup.string().required("Bitte Ort ausfüllen"),
    country: Yup.string(),
    email: Yup.string().email("Bitte Email richtig ausfüllen").required("Bitte Email ausfüllen"),
    phone: Yup.string(),
  })

  return (
    <div>
      <div className="form_title">Kontaktangaben für Offerte</div>

      <Formik
        innerRef={formRef}
        initialValues={{
          salutation: UserData.salutation,
          firstname: UserData.firstname,
          lastname: UserData.lastname,
          street: UserData.street,
          streetnr: UserData.streetnr,
          plz: UserData.plz,
          place: UserData.place,
          country: UserData.country,
          email: UserData.email,
          phone: UserData.phone,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            console.log(JSON.stringify(values, null, 2))
            setSubmitting(false)
            UserData["isset"]=true
            navigate("/04", { replace: true })
          }, 400)
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props

          try {
            UserData.salutation = values.salutation
            UserData.firstname = values.firstname
            UserData.lastname = values.lastname
            UserData.street = values.street
            UserData.streetnr = values.streetnr
            UserData.plz = values.plz
            UserData.place = values.place
            UserData.country = values.country
            UserData.email = values.email
            UserData.phone = values.phone
          } catch {
            console.log("Couldn't load userdata")
          }

          console.log(UserData)

          return (
            <Form>
              <Field
                autoComplete="salutation"
                placeholder="Anrede"
                type="text"
                name="salutation"
                className={
                  errors.salutation && touched.salutation ? "text-input error" : "text-input"
                }
              />
              <ErrorMessage name="salutation" component="div" className="errormessage" />

              <Field
                autoComplete="firstname"
                placeholder="Vorname"
                type="text"
                name="firstname"
                className={
                  errors.firstname && touched.firstname ? "text-input error" : "text-input"
                }
              />
              <ErrorMessage name="firstname" component="div" className="errormessage" />

              <Field
                autoComplete="lastname"
                placeholder="Nachname"
                type="text"
                name="lastname"
                className={errors.lastname && touched.lastname ? "text-input error" : "text-input"}
              />
              <ErrorMessage name="lastname" component="div" className="errormessage" />

              <hr></hr>

              <div className="clear_both">
                <div className="adress">
                  <Field
                    autoComplete="street"
                    placeholder="Strasse"
                    type="text"
                    name="street"
                    className={errors.street && touched.street ? "text-input error" : "text-input"}
                  />
                  <ErrorMessage name="street" component="div" className="errormessage" />
                </div>
                <div className="adress_nr">
                  <Field
                    autoComplete="streetnr"
                    placeholder="Nr"
                    type="text"
                    name="streetnr"
                    className={
                      errors.streetnr && touched.streetnr ? "text-input error" : "text-input"
                    }
                  />
                  <ErrorMessage name="streetnr" component="div" className="errormessage" />
                </div>
              </div>

              <div className="clear_both">
                <div className="plz">
                  <Field
                    autoComplete="plz"
                    placeholder="PLZ"
                    type="text"
                    name="plz"
                    className={errors.plz && touched.plz ? "text-input error" : "text-input"}
                  />
                  <ErrorMessage name="plz" component="div" className="errormessage" />
                </div>

                <div className="place">
                  <Field
                    autoComplete="place"
                    placeholder="Ort"
                    type="text"
                    name="place"
                    className={errors.place && touched.place ? "text-input error" : "text-input"}
                  />
                  <ErrorMessage name="place" component="div" className="errormessage" />
                </div>
              </div>

              <Field as="select" name="country">
                <option value="Schweiz">Schweiz</option>

                {countryList()
                  .native()
                  .getData()
                  .map((item) => (
                    <option value={item.label}>{item.label}</option>
                  ))}
              </Field>
              <hr></hr>

              <Field
                autoComplete="name"
                placeholder="Email"
                type="text"
                name="email"
                className={errors.email && touched.email ? "text-input error" : "text-input"}
              />
              <ErrorMessage name="email" component="div" className="errormessage" />

              <Field
                autoComplete="phone"
                placeholder="Telefon (optional)"
                type="text"
                name="phone"
                className={errors.phone && touched.phone ? "text-input error" : "text-input"}
              />
              <ErrorMessage name="phone" component="div" className="errormessage" />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
