import "./Loading_styles.css"

export default function Loader() {
  return (
      <div class="loader">
        <div class="square"></div>
        <div class="square"></div>
        <div class="square last"></div>
        <div class="square clear"></div>
        <div class="square"></div>
        <div class="square last"></div>
        <div class="square clear"></div>
        <div class="square "></div>
        <div class="square last"></div>
      </div>
  )
}
