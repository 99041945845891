/////////// CHECK YOUR INFORMATION + SUBMIT PAGE ///////////
import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import QRCode from "./PDF_Generator.js"
import { getUrl } from "./spezifications/Spezification_Functions.js"
import UserData from "./spezifications/User_Contactinfo.js"
import { getAttribute, getTotalPrice } from "./spezifications/Spezification_Functions.js"

////// display the price correctly //////
function PreiszuText(price, nobrackets) {
  if (price === 0 || typeof price === "undefined") {
    const new_price = ""
    return new_price
  } else {
    if (nobrackets) {
      const new_price = price.toLocaleString("de-CH") + " CHF"
      return new_price
    } else {
      const new_price = " (+" + price.toLocaleString("de-CH") + " CHF)"
      return new_price
    }
  }
}

////// gets a specific entry from the form //////
function readUserData(name) {
  try {
    return UserData[name]
  } catch {
    console.log("couldn't read: " + name)
  }
}

////// user can check the configuration + formdata and send an email //////
export default function App() {
  useEffect(() => {
    window.onbeforeunload = confirmExit
    function confirmExit() {
      return "Ihre Änderugen werden nicht gespeichert"
    }
  }, [])

  let navigate = useNavigate()
  useEffect(() => {
    if(!UserData["isset"]){
      console.log("keine Kontaktangaben - zurück zu Konfigurator Home")
      navigate("/", { replace: true })
    }
  }, {})

  return (
    <>
      <div id="main_form_upper">
        <div className="form_container">
          <div className="form_width">
            <br />
            <div className="form_title">Angaben überprüfen</div>
            Bitte überprüfen Sie ihre Angaben nochmals, bevor Sie die das Angebot anfordern:
            <hr />
            {/*-------------------------Spezifications---------------------------------*/}
            Modell:{" "}
            <b>
              {getAttribute("Modell", "specification")}
              {PreiszuText(getAttribute("Modell", "price"))}
            </b>{" "}
            <br />
            Holz: <b>{getAttribute("Holz", "specification")}</b> <br />
            Griffbrett: <b>{getAttribute("Griffbrett", "specification")}</b>
            <br />
            Intarsien: <b>{getAttribute("Intarsien", "specification")} </b>
            <br />
            Balgrahmen:{" "}
            <b>
              {" "}
              {getAttribute("Balgrahmen", "specification")}{" "}
              {PreiszuText(getAttribute("Balgrahmen", "price"))}
            </b>
            <br />
            Balgfalten Kunstleder: <b> {getAttribute("Balgfalten_Kunstleder", "specification")}</b>
            <br />
            Balgpapier: <b> {getAttribute("Balgpapier", "specification")}</b>
            <br />
            Balg Lederecken: <b> {getAttribute("Balg_Lederecken", "specification")}</b>
            <br />
            Balgrahmen Kunstleder: <b> {getAttribute("Balgrahmen_Kunstleder", "specification")}</b>
            <br />
            Knöpfe:{" "}
            <b>
              {getAttribute("Knöpfe", "specification")}
              {PreiszuText(getAttribute("Knöpfe", "price"))}
            </b>
            <br />
            <br />
            <b>Total: {PreiszuText(getTotalPrice(), true)}</b>
            <br />
            {/*---------------------------Contactinfos-------------------------------*/}
            <hr />
            {readUserData("firstname")} {readUserData("lastname")}
            <br />
            {readUserData("street")} {readUserData("streetnr")}
            <br />
            {readUserData("plz")} {readUserData("place")}
            <br />
            {readUserData("country")}
            <hr />
            <b>{readUserData("email")}</b> <br />
            <b>{readUserData("phone")}</b>
            <br />
            bevorzuge per {readUserData("preferredcommunication")} kontaktiert <br />
            {/*---------------------------Kommentar-------------------------------*/}
            {readUserData("comment")}
            <hr />
            {/*---------------------------Offerte anfordern-------------------------------*/}
            <div className="form_title">Offerte anfordern</div>
            (Die Offerte wird automatisch an Ihre Emailadresse gesendet)
            <div>
              <QRCode
                text="Email senden"
                waittext="bitte warten"
                formdata={UserData}
                url={getUrl()}
                hideformdata={false}
                SendMail={true}
              />
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
      <div id="main_form_under">
        <div className="navigation_arrow">
          <Link key="04" to="/04">
            <img src="images/icons/arrow_left.png" alt="arrow_left" className="arrow_left" />
          </Link>
        </div>
      </div>
    </>
  )
}
