import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"


function Verdeck_Melodieseite_Wiener_Standard(props) {
  const group = useRef()

  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Wiener_Standard.glb')

  return(
    <group>
     <mesh geometry={nodes.Verdeck_Melodieseite_Wiener_Standard.geometry} material={materials.Holz_Loecher}  material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Verdeck_Melodieseite_Wiener_Standard_1.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)} />
    </group>
  )
}

function Verdeck_Melodieseite_Wiener_Ornament(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Wiener_Ornament.glb')

  return(
    <group>
      <mesh geometry={nodes.Verdeck_Melodieseite_Wiener_Ornament.geometry} material={materials.Holz_01}  material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Verdeck_Melodieseite_Wiener_Ornament_1.geometry} material={materials.Holz_Loecher}  material-map={useTexture(snap.items.Holz)}/>
    </group>
  )
}

function Verdeck_Melodieseite_Halbwiener_Ornament(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Halbwiener_Ornament.glb')

  return(
    <group>
      <mesh geometry={nodes.Verdeck_Melodieseite_Halbwiener_Ornament.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Verdeck_Melodieseite_Halbwiener_Ornament_1.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)} />
    </group>
  )
}

function Verdeck_Melodieseite_TN(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Standard.glb')

  return(
    <group>
      <mesh geometry={nodes.Verdeck_Melodieseite_Standard.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Verdeck_Melodieseite_Standard_1.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)} />
    </group>
  )
}

function Verdeck_Melodieseite_TE(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_TE_GS.glb')

  return(
    <group>
      <mesh geometry={nodes.Verdeck_Melodieseite_TE_GS.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Verdeck_Melodieseite_TE_GS_1.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)} />
    </group>
  )
}

function Verdeck_Melodieseite_Natura(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Natura.glb')

  return(
    <group>
      <mesh geometry={nodes.Verdeck_Melodieseite_Natura.geometry} material={materials.Holz_Loecher}  material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Verdeck_Melodieseite_Natura_1.geometry} material={materials.Holz_01}  material-map={useTexture(snap.items.Holz)}/>
    </group>
  )
}

function Verdeck_Melodieseite_Gravur(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Gravur.glb')

  return(
    <group>
      <mesh geometry={nodes.Verdeck_Melodieseite_Gravur.geometry} material={materials.Verdeck_Gravur} material-map={useTexture(snap.items.Balgrahmen)} material-alphaMap={useTexture(snap.items.VerdeckGravur)} material-alphaTest={0.5} material-opacity={1} transparent/>
      <mesh geometry={nodes.Verdeck_Melodieseite_Gravur_1.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Verdeck_Melodieseite_Gravur_2.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
    </group>
  )
}

function Verdeck_Melodieseite_Pin(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Pin.glb')

  return(
    <group>
      <mesh geometry={nodes.Verdeck_Melodieseite_Pin.geometry} material={materials.Verdeck_Edelweiss} />
      <mesh geometry={nodes.Verdeck_Melodieseite_Pin_1.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)} />
      <mesh geometry={nodes.Verdeck_Melodieseite_Pin_2.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
    </group>
  )
  
}


export default function Oergeli_Verdeck_Melodieseite(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Verdeck_Melodieseite_Standard.glb')

  if(snap.items.Modell.includes(" HW")){
    return(<Verdeck_Melodieseite_Halbwiener_Ornament snap={snap}/>)
  }else if(snap.items.Modell.includes(" W")){
    return(<Verdeck_Melodieseite_Wiener_Ornament snap={snap}/>)
  }else if(snap.items.Modell.includes(" TE")||snap.items.Verdeck=="Typ Eichhorn"||snap.items.Modell.includes(" GS")){
    return(<Verdeck_Melodieseite_TE snap={snap}/>)
  }else if(snap.items.Verdeck=="Natura"){
    return(<Verdeck_Melodieseite_Natura snap={snap}/>)
  }else if(snap.items.Verdeck=="mit Edelweiss-Gravur"){
    return(<Verdeck_Melodieseite_Gravur snap={snap}/>)
  }else if(snap.items.Verdeck=="mit Edelweiss-Pin"){
    return(<Verdeck_Melodieseite_Pin snap={snap}/>)
  }else{
    return(<Verdeck_Melodieseite_TN snap={snap}/>)
  }

  return(<></>)
}
