/////////// OPTIONS: "DOWNLOAD PDF" / "CONTACT WITH OFFER" ///////////
import React from "react"
import { Link } from "react-router-dom"
import PopupDownload from "./Form_02_Popup_Download.js"

export default function App() {
  return (
    <>
      <div id="main_form_upper">
        <div className="form_container">
          <div className="form_width">
            <div className="form_title">Wollen Sie schon Kontakt aufnehmen?</div>
            Hier können Sie ihre Konfiguration downloaden:
            <br />
            <PopupDownload />
            <br />
            <br />
            Wenn Sie bereit sind, können Sie mit uns Kontakt aufnehmen:
            <br />
            <Link key="03" to="/03">
              <button className="auto_button_width">Angebot anfragen</button>
            </Link>
          </div>
        </div>
      </div>
      <div id="main_form_under">
        <div className="navigation_arrow">
          <Link key="home" to="/">
            <img src="images/icons/arrow_left.png" alt="arrow_left" className="arrow_left" />
          </Link>
        </div>
      </div>
    </>
  )
}
