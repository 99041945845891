import Model_EdgarOtt from "../menu/Model_EdgarOtt.js"
import Model_EdgarOtt_TE from "../menu/Model_EdgarOtt_TE.js"
import Model_EdgarOtt_TN from "../menu/Model_EdgarOtt_TN.js"
import Model_EdgarOtt_GS from "../menu/Model_EdgarOtt_GS.js"
import Model_EdgarOtt_Traditionell from "../menu/Model_EdgarOtt_Traditionell.js"


const Modell_Kategorie = [
  {
    id: 1,
    title: "EDGAR OTT / EGGI",
    value: "EDGAR OTT / EGGI",
    list: Model_EdgarOtt,
    price: 0,
  },
  {
    id: 2,
    title: "EDGAR OTT TE",
    value: "EDGAR OTT TE",
    list: Model_EdgarOtt_TE,
    price: 0,
  },
  {
    id: 3,
    title: "EDGAR OTT TN",
    value: "EDGAR OTT TN",
    list: Model_EdgarOtt_TN,
    price: 0,
  },
  {
    id: 4,
    title: "EDGAR OTT GS",
    value: "EDGAR OTT GS",
    list: Model_EdgarOtt_GS,
    price: 0,
  },
  {
    id: 5,
    title: "EDGAR OTT Traditionell",
    value: "EDGAR OTT Traditionell",
    list: Model_EdgarOtt_Traditionell,
    price: 0,
  },
]
export default Modell_Kategorie
