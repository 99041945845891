import React, { Fragment } from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import IdSpezification from "../spezifications/Spezification_Functions.js"

function PreiszuText(price) {
  if (price === 0) {
    const new_price = price + " CHF"
    return new_price
  } else {
    const new_price = price.toLocaleString("de-CH") + " CHF"
    return new_price
  }
}

const borderColor = "#d1d1d1"
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderTopColor: borderColor,
    borderTopWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  rowTotal: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    borderTopColor: "black",
    borderTopWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  rowDescription: {
    fontWeight: 700,
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    fontWeight: 700,
    width: "75%",
    textAlign: "left",
    paddingLeft: 8,
  },
  amount: {
    width: "25%",
    textAlign: "right",
    paddingRight: 8,
  },
  total: {
    width: "25%",
    textAlign: "right",
    paddingRight: 8,
  },
  table: {
    marginTop: 35,
  },
})

const Table = () => {
  const rows = IdSpezification.map(({ id, specification, price }) => (
    <View style={styles.row} key={id}>
      <Text style={styles.description}>{specification}</Text>
      <Text style={styles.amount}>{PreiszuText(price)}</Text>
    </View>
  ))

  var total = 0
  IdSpezification.map(({ price }) => (total = total + price))
  return (
    <View style={styles.table}>
      <View style={styles.rowDescription}>
        <Text style={styles.description}>Optionen</Text>
        <Text style={styles.total}>Preis</Text>
      </View>
      <Fragment>{rows}</Fragment>
      <View style={styles.rowTotal}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>{PreiszuText(total)}</Text>
      </View>
    </View>
  )
}

export default Table
