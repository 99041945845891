////////The data from the form gets saved here////////
var UserContactinfo = {
  isset: false,
  salutation: "",
  firstname: "",
  lastname: "",
  street: "",
  streetnr: "",
  plz: "",
  place: "",
  birthday: "1980-01-01",
  country: "Schweiz",
  email: "",
  phone: "",
  comment: "",
  preferredcommunication: "Telefon",
}
export default UserContactinfo

////////Reset (is used after submitting)////////
export function ResetContatcinfo(){
  UserContactinfo = {
    isset: false,
    salutation: "",
    firstname: "",
    lastname: "",
    street: "",
    streetnr: "",
    plz: "",
    place: "",
    birthday: "1980-01-01",
    country: "Schweiz",
    email: "",
    phone: "",
    comment: "",
    preferredcommunication: "Telefon",
  }
}