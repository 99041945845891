import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"


function Oergeli_Intarsien_Melodieseite_Standard(props){
const group = useRef()
const snap = props.snap
const { nodes, materials } = useGLTF("./assets/Base.glb")

return (
  <>
    <group>
      <mesh geometry={nodes.Base_10.geometry} material={materials.Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.5} material-opacity={1} transparent />
    </group>
  </>
)
}

function Oergeli_Intarsien_Melodieseite_TypEichhornRot212(props){
const group = useRef()
const snap = props.snap
const { nodes, materials } = useGLTF("./assets/Intarsien_Balgrahmen_Melodieseite_TypEichhornRot212.glb")

return (
  <>
   <group>
        <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
    </group>
  </>
)
}


function Oergeli_Intarsien_Melodieseite_TypEichhorn4(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Intarsien_Balgrahmen_Melodieseite_TypEichhorn4.glb")
  
  return (
    <>
     <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
      </group>
    </>
  )
  }






///////////////////
function Oergeli_Intarsien_Balgrahmen(props) {
  const group = useRef()
  const snap = props.snap

  if(snap.items.StreifenIntarsien){
    if(snap.items.Intarsien.includes("eichhorn_rot_212")){
      return (
        <>
          <Oergeli_Intarsien_Balgrahmen_TypEichhornRot212 snap={snap}/>
        </>
      )
    }else if(snap.items.Intarsien.includes("eichhorn_4")){
      return (
        <>
          <Oergeli_Intarsien_Balgrahmen_TypEichhorn4 snap={snap}/>
        </>
      )
    }else{
      return (
        <>
          <Oergeli_Intarsien_Balgrahmen_Standard snap={snap}/>
        </>
      )
    }
  }else{
    return (
      <></>
    )
  }
}


function Oergeli_Intarsien_Balgrahmen_Standard(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Base.glb")
  return (
    <>
      <mesh geometry={nodes.Base_5.geometry} material={materials.Balgrahmen_Streifen_Intarsien} material-map={useTexture(snap.items.Intarsien) } material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
    </>
  )
}

function Oergeli_Intarsien_Balgrahmen_TypEichhornRot212(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Balgrahmen_Streifen_Intarsien_TypEichhornRot212.glb")
  return (
    <>
     <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Balgrahmen_Streifen_Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
      </group>
    </>
  )
}
function Oergeli_Intarsien_Balgrahmen_TypEichhorn4(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Balgrahmen_Streifen_Intarsien_TypEichhorn4.glb")
  return (
    <>
     <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Balgrahmen_Streifen_Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
      </group>
    </>
  )
}


///////////////////////////////////////////////

function Oergeli_Intarsien_Bassseite(props) {
    const group = useRef()
    const snap = props.snap
  
    if(snap.items.Modell.includes(" GS")||snap.items.Modell.includes(" TN")||snap.items.Modell.includes(" TE")||snap.items.Modell.includes(" Traditionell")){
      if(snap.items.Intarsien.includes("typ_eichhorn_rot_212")){
        return (
          <>
            <Oergeli_Intarsien_Bassseite_TypEichhornRot212 snap={snap}/>
          </>
        )
      }else if(snap.items.Intarsien.includes("eichhorn_4")){
        return (
          <>
            <Oergeli_Intarsien_Bassseite_TypEichhorn4 snap={snap}/>
          </>
        )
      }else{
        return (
          <>
            <Oergeli_Intarsien_Bassseite_Standard snap={snap}/>
          </>
        )
      }
    }else{
      return (
        <></>
      )
    }
}


function Oergeli_Intarsien_Bassseite_Standard(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Intarsien_Balgrahmen_Basseite.glb")

  return (
    <>
     <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
      </group>
    </>
  )
}

function Oergeli_Intarsien_Bassseite_TypEichhornRot212(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Intarsien_Balgrahmen_Basseite_TypEichhornRot212.glb")

  return (
    <>
     <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
      </group>
    </>
  )
}

function Oergeli_Intarsien_Bassseite_TypEichhorn4(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Intarsien_Balgrahmen_Basseite_TypEichhorn4.glb")

  return (
    <>
     <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien} material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
      </group>
    </>
  )
}




function Intarsien_Standard(props){
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Intarsien.glb")
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien}  material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
        </group>
      </>
    )
}
  
function Intarsien_TypEichhornRot212(props){
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Intarsien_TypEichhornRot212.glb")

    return (
        <>
            <group>
                <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien}  material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
            </group>
        </>
    )
}

function Intarsien_TypEichhorn4(props){
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Intarsien_TypEichhorn4.glb")

  return (
      <>
          <group>
              <mesh geometry={nodes.Oergeli.geometry} material={materials.Intarsien}  material-map={useTexture(snap.items.Intarsien)} material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
          </group>
      </>
  )
}

export default function Intarsien(props){
    const snap = props.snap

    if(snap.items.Intarsien.includes("eichhorn_4")){
        return (
            <>
                <Oergeli_Intarsien_Bassseite snap={snap}/>
                <Oergeli_Intarsien_Balgrahmen snap={snap}/>
                <Intarsien_TypEichhorn4 snap={snap}/>
                <Oergeli_Intarsien_Melodieseite_TypEichhorn4 snap={snap}/>
            </>
        )
    }else if(snap.items.Intarsien.includes("typ_eichhorn_rot_212")){
        return (
            <>
                <Oergeli_Intarsien_Bassseite snap={snap}/>
                <Oergeli_Intarsien_Balgrahmen snap={snap}/>
                <Intarsien_TypEichhornRot212 snap={snap}/>
                <Oergeli_Intarsien_Melodieseite_TypEichhornRot212 snap={snap}/>
            </>
        )
    }else{
        return (
            <>
                <Oergeli_Intarsien_Bassseite snap={snap}/>
                <Oergeli_Intarsien_Balgrahmen snap={snap}/>
                <Intarsien_Standard snap={snap}/>
                <Oergeli_Intarsien_Melodieseite_Standard snap={snap}/>
            </>
        )
    }
}