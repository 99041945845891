import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"

function Basseite_Celluloid_Natura(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Basseite_Celluloid_Natura.glb")

  return (
    <>
      <group>
        <mesh geometry={nodes.Basseite_Celluloid_Natura.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}  />
        <mesh geometry={nodes.Basseite_Celluloid_Natura_1.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Holz)} />
        <mesh geometry={nodes.Basseite_Celluloid_Natura_2.geometry} material={materials.Celluloid} material-map={useTexture(snap.items.Griffbrett)}/>
      </group>
    </>
  )
}


function Basseite_Holz_Natura(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Basseite_Holz_Natura.glb")

  return (
    <>
      <group>
        <mesh geometry={nodes.Basseite_Holz_Natura.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}  />
        <mesh geometry={nodes.Basseite_Holz_Natura_1.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Griffbrett)}/>
      </group>
    </>
  )
}

function Basseite_Celluloid_Standard(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Basseite_Celluloid.glb")

  return (
    <>
      <group>
        <mesh geometry={nodes.Basseite_Celluloid.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Griffbrett)}  />
        <mesh geometry={nodes.Basseite_Celluloid_1.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Holz)}/>
        <mesh geometry={nodes.Basseite_Celluloid_2.geometry} material={materials.Celluloid} material-map={useTexture(snap.items.Griffbrett)}/>
      </group>
    </>
  )
}

function Basseite_Holz_Standard(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Basseite_Holz.glb")

  return (
    <>
      <group>
        <mesh geometry={nodes.Basseite_Holz.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
        <mesh geometry={nodes.Basseite_Holz_1.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Griffbrett)}/>
      </group>
    </>
  )
}

export default function Bassseite_Griffbrett(props) {
  const snap = props.snap


  if(snap.items.Verdeck=="Natura"){
    if(snap.items.Griffbrett.includes("celluloid")){
      return(<Basseite_Celluloid_Natura snap={snap} />)
    }else{
      return(<Basseite_Holz_Natura snap={snap} />)
    }
  }else{
    if(snap.items.Griffbrett.includes("celluloid")){
      return(<Basseite_Celluloid_Standard snap={snap} />)
    }else{
      return(<Basseite_Holz_Standard snap={snap} />)
    }
  }
}