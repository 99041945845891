import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"

function Bassseite_2teilig(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Bassseite_2teilig.glb")

  return (
    <>
      <group>
        <mesh geometry={nodes.Bassseite_2teilig.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Griffbrett)}/>
        <mesh geometry={nodes.Bassseite_2teilig_1.geometry} material={materials.Metal} />
        <mesh geometry={nodes.Bassseite_2teilig_2.geometry} material={materials.Leder} />
        <mesh geometry={nodes.Bassseite_2teilig_3.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
      </group>
    </>
  )
}

function Bassseite_1teilig(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Bassseite_1teilig.glb")

  return (
    <>
      <group>
        <mesh geometry={nodes.Bassseite_1teilig.geometry} material={materials.Metal} />
        <mesh geometry={nodes.Bassseite_1teilig_1.geometry} material={materials.Leder} />
        <mesh geometry={nodes.Bassseite_1teilig_2.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)} />
      </group>
    </>
  )
}

export default function Oergeli_Bassseite(props) {
  const snap = props.snap

  if(snap.items.Modell.includes(" TE")||snap.items.Modell.includes(" Traditionell")||snap.items.Modell.includes(" TN")||snap.items.Modell.includes(" GS")){
    return (
      <Bassseite_2teilig snap={snap} />
    )
  }else{
    return (
      <Bassseite_1teilig snap={snap} />
    )
  }
}