import React, { useState, useEffect } from 'react';
import Form from "./Form_00_Main.js"
import Viewport from "./Viewport.js"
import Form00Model from "./Form_00_Model"
import { LoadIdFromUrl } from "./spezifications/Spezification_Functions.js"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"


function ConfigurationMode(){
  LoadIdFromUrl()
  return(
    <>
    {/* ------- Navigation ------- */}
    <div id="main_form">
      <Form />
    </div>

    {/* ------- Viewport with 3D-Model ------- */}
    <div id="main_preview">
      <Viewport
        fov="40"
        id="oergeli_preview"
        preserveDrawingBuffer="false"
        background={["#f4f2f2"]}
        noIcon={false}
      />
    </div>
    </>
  )
}

function SelectModel(props){
  if(props.intern==true){
    
  }
  return(
    <div id="main_modellselection">
      <Form00Model intern={props.intern} />
    </div>
  )
}



export default function App() {
  const [intern, setIntern] = useState(false)
  //The Configuration can be saved in the URL - it gets loaded here
 
  useEffect(() => {
    if(window.location.href.indexOf("modell_intern") > -1) {
      setIntern(true)
    }
  }, []);


  return (
    <>
    
      <div id="window_field">
        {/* ------- Logo ------- */}
        <div id="logo">
          <a href="https://ott-oergeli.ch">
            <img src="images/logo.png" alt="Logo" id="logo" />
          </a>
        </div>
        <BrowserRouter basename={""}>
          <Routes>
              <Route key="selectModel" path="/modell" element={<SelectModel intern={intern}/>} /> 
              <Route key="selectModelIntern" path="/modell_intern" element={<SelectModel intern={intern}/> }/>  
              <Route key="configurationMode" path="/te/*" element={<ConfigurationMode intern={intern}/>} />  
              <Route key="configurationMode" path="/gs/*" element={<ConfigurationMode intern={intern}/>} />  
              <Route key="configurationMode" path="/tn/*" element={<ConfigurationMode intern={intern}/>} /> 
              <Route key="configurationMode" path="/tk/*" element={<ConfigurationMode intern={intern}/>} />  
              <Route key="configurationMode" path="*" element={<ConfigurationMode intern={intern}/>} />  
          </Routes>
        </BrowserRouter>
    </div>
  </>

  )
}
