function description(title) {
  switch (title) {
    case "EGGI 2":
      return (
        <>
          -2-chörig
          <br />
          -18 Bässe
          <br />
          -a mano-Stimmen
        </>
      )
    case "EGGI 3":
      return (
        <>
          -3-chörig
          <br />
          -18 Bässe
          <br />
          -a mano-Stimmen
        </>
      )
    case "EDGAR OTT 2":
      return (
        <>
          -2-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT 3":
      return (
        <>
          -3-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT TE 2":
      return (
        <>
          -Traditionell nach Eichhorn
          <br />
          -2-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT TE 3":
      return (
        <>
          -Traditionell nach Eichhorn
          <br />
          -3-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT TE W":
      return (
        <>
          -Traditionell nach Eichhorn
          <br />
          -Wiener, 2-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT TN 2":
      return (
        <>
          -Traditionell nach Nussbaumer
          <br />
          -2-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT TN 3":
      return (
        <>
          -Traditionell nach Nussbaumer
          <br />
          -3-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT TN W":
      return (
        <>
          -Traditionell nach Nussbaumer
          <br />
          -Wiener, 2-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT GS 2":
      return (
        <>
          -gestemmte Stimmen
          <br />
          -2-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT GS 3":
      return (
        <>
          -gestemmte Stimmen
          <br />
          -3-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT GS W":
      return (
        <>
          -gestemmte Stimmen
          <br />
          -Wiener, 2-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    case "EDGAR OTT GS HW":
      return (
        <>
          -gestemmte Stimmen
          <br />
          -Halbwiener, 3-chörig
          <br />
          -18 Bässe
          <br />
          -DIX Kombi-Stimmen
        </>
      )
    default:
      return ""
  }
}

//8! label is needed because it is a text input
const Modell = [
  {
    id: 1,
    title: "EGGI 2",
    value: "EGGI 2",
    label: "EGGI 2 (6110 CHF)",
    price: 6110,
    description: description("EGGI 2"),
    choere: "2-chörig",
    stimmen: "a mano-Stimmen",
  },
  {
    id: 2,
    title: "EGGI 3",
    value: "EGGI 3",
    label: "EGGI 3 (6820 CHF)",
    price: 6820,
    description: description("EGGI 3"),
    choere: "3-chörig",
    stimmen: "a mano-Stimmen",
  },
  {
    id: 3,
    title: "EDGAR OTT 2",
    value: "EDGAR OTT 2",
    label: "EDGAR OTT 2 (7130 CHF)",
    price: 7130,
    description: description("EDGAR OTT 2"),
    choere: "2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 4,
    title: "EDGAR OTT 3",
    value: "EDGAR OTT 3",
    label: "EDGAR OTT 3 (7850 CHF)",
    price: 7850,
    description: description("EDGAR OTT 3"),
    choere: "3-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 5,
    title: "EDGAR OTT TE 2",
    value: "EDGAR OTT TE 2",
    label: "EDGAR OTT TE 2 (7980 CHF)",
    price: 7980,
    description: description("EDGAR OTT TE 2"),
    choere: "2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 6,
    title: "EDGAR OTT TE 3",
    value: "EDGAR OTT TE 3",
    label: "EDGAR OTT TE 3 (8680 CHF)",
    price: 8680,
    description: description("EDGAR OTT TE 3"),
    choere: "3-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 7,
    title: "EDGAR OTT TE W",
    value: "EDGAR OTT TE W",
    label: "EDGAR OTT TE W (8980 CHF)",
    price: 8980,
    description: description("EDGAR OTT TE W"),
    choere: "Wiener, 2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 8,
    title: "EDGAR OTT GS 2",
    value: "EDGAR OTT GS 2",
    label: "EDGAR OTT GS 2 (11’110 CHF)",
    price: 11110,
    description: description("EDGAR OTT GS 2"),
    choere: "2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 9,
    title: "EDGAR OTT GS 3",
    value: "EDGAR OTT GS 3",
    label: "EDGAR OTT GS 3 (12’130 CHF)",
    price: 12130,
    description: description("EDGAR OTT GS 3"),
    choere: "3-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 10,
    title: "EDGAR OTT GS HW",
    value: "EDGAR OTT GS HW",
    label: "EDGAR OTT GS HW (13’970 CHF)",
    price: 13970,
    description: description("EDGAR OTT GS HW"),
    choere: "Halbwiener, 3-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 11,
    title: "EDGAR OTT GS W",
    value: "EDGAR OTT GS W",
    label: "EDGAR OTT GS W (12’440 CHF)",
    price: 12440,
    description: description("EDGAR OTT GS W"),
    choere: "Wiener, 2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 12,
    title: "EDGAR OTT TN 2",
    value: "EDGAR OTT TN 2",
    label: "EDGAR OTT TN 2 (7980 CHF)",
    price: 7980,
    description: description("EDGAR OTT TN 2"),
    choere: "2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 13,
    title: "EDGAR OTT TN 3",
    value: "EDGAR OTT TN 3",
    label: "EDGAR OTT TN 3 (8680 CHF)",
    price: 8680,
    description: description("EDGAR OTT TN 3"),
    choere: "3-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 14,
    title: "EDGAR OTT TN W",
    value: "EDGAR OTT TN W",
    label: "EDGAR OTT TN W (8980 CHF)",
    price: 8980,
    description: description("EDGAR OTT TN W"),
    choere: "Wiener, 2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 15,
    title: "EDGAR OTT Traditionell 2",
    value: "EDGAR OTT Traditionell 2",
    label: "EDGAR OTT Traditionell 2 (8150 CHF)",
    price: 8150,
    description: description("EDGAR OTT Traditionell 2"),
    choere: "2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 16,
    title: "EDGAR OTT Traditionell 3",
    value: "EDGAR OTT Traditionell 3",
    label: "EDGAR OTT Traditionell 3 (8870 CHF)",
    price: 8870,
    description: description("EDGAR OTT Traditionell 3"),
    choere: "3-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
  {
    id: 17,
    title: "EDGAR OTT Traditionell W",
    value: "EDGAR OTT Traditionell W",
    label: "EDGAR OTT Traditionell W (9170 CHF)",
    price: 9170,
    description: description("EDGAR OTT Traditionell W"),
    choere: "Wiener, 2-chörig",
    stimmen: "DIX Kombi-Stimmen",
  },
]

export default Modell
