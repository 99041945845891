const id_spezifications = [
  {
    label: "Modell",
    items: ["EDGAR OTT TE 2", "EDGAR OTT TE 3", "EDGAR OTT TE W"]
  },
  {
    label: "Knöpfe",
    items: ["Teflon rund", "Teflon halbrund"]
  },
  {
    label: "Holz",
    items: ["Ahorn", "Kirschbaum"]
  },
  {
    label: "Griffbrett",
    items: ["Celluloid grün-gold maser"]
  },
  {
    label: "Intarsien",
    items: ["Typ Eichhorn 4"]
  },
  {
    label: "Balgrahmen",
    items: ["Intarsie Typ Eichhorn schräg"]
  },
  {
    label: "Balgrahmen_Kunstleder",
    items: ["dunkelbraun", "dunkelrot", "dunkelgrün", "dunkelblau", "schwarz"]
  },
  {
    label: "Balgfalten_Kunstleder",
    items: [ "dunkelbraun", "dunkelrot", "dunkelgrün", "dunkelblau", "schwarz"]
  },
  {
    label: "Balg_Lederecken",
    items: ["beige", "rot 82", "dunkelrot", "dunkelblau", "dunkelgrün", "schwarz"]
  },
  {
    label: "Balgpapier",
    items: ["Sternli und Möndli grün Ott", "Sternli grün Ott", "Sternli weiss Ott", "Sternli rot Ott", "Beige Ott", "Marmor weissgold 933", "Marmor weiss-grau 1570", "Edelweiss Ott", "Tassotti blau 465", "Tassotti rot 464", "Tassotti Kornblumen", "Tassotti Astern 473", "Tassotti Blumen Bouket 492", "Tassotti Danza di Fiori", "Carta florentina Blüten", "Tassotti Pusteblume 1573", "Tassotti Viola 474", "Tassotti Goldblume 7024", "Tassotti Goldschnörkel 711"]
  },
]
export default id_spezifications
