import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"
import  { getAttribute } from "../spezifications/Spezification_Functions.js"


function Breites_Lederband(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Base.glb")
  
    return (
      <>
        <group>
            <mesh geometry={nodes.Base_2.geometry} material={materials.Balgrahmen_Kunstleder_Papierband} material-color={snap.items.BalgrahmenKunstleder} />
        </group>
      </>
    )
}

function Holz(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Base.glb")
  
    return (
      <>
        <group>
            <mesh geometry={nodes.Base_2.geometry} material={materials.Balgrahmen_Kunstleder_Papierband} material-map={useTexture(snap.items.Holz02)}/>
        </group>
      </>
    )
}

function Balgrahmen_Eichhorn(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Balgrahmen_Eichhorn.glb")
  
    return (
      <>
        <group>
            <mesh geometry={nodes.Oergeli.geometry} material={materials.Balgrahmen}  material-map={useTexture(snap.items.Balgrahmen)}  material-alphaMap={useTexture(snap.items.BalgrahmenAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
        </group>
      </>
    )
}


function Balgrahmen_Mauro(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Balgrahmen_Mauro.glb")
  
    return (
      <>
        <group>
            <mesh geometry={nodes.Oergeli.geometry} material={materials.Balgrahmen}  material-map={useTexture(snap.items.Balgrahmen)}  material-alphaMap={useTexture(snap.items.BalgrahmenAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
        </group>
      </>
    )
}



function Balgrahmen_Holz_Edelweiss(props){
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Base.glb")
    return(
        <>
            <mesh geometry={nodes.Base_2.geometry} material={materials.Holz_02} material-map={useTexture(snap.items.Holz02)} />
            <mesh geometry={nodes.Base_3.geometry} material={materials.Balgrahmen_DifferentUV} material-map={useTexture(snap.items.Balgrahmen)} material-alphaMap={useTexture(snap.items.BalgrahmenAlpha)} material-alphaTest={0.5} material-opacity={1} transparent/>
        </>
    )
}

function Balgrahmen_Holz_Others(props){
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Base.glb")
    return(
        <>
            <mesh geometry={nodes.Base_2.geometry} material={materials.Holz_02} material-map={useTexture(snap.items.Holz02)} />
            <mesh geometry={nodes.Base.geometry} material={materials.Balgrahmen} material-map={useTexture(snap.items.Balgrahmen)} material-alphaMap={useTexture(snap.items.BalgrahmenAlpha)} material-alphaTest={0.5} material-opacity={1} transparent />
        </>
    )
}

   


function Balgrahmen_Holz(props){
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Base.glb")

  
    console.log("Balgrahmen: "+snap.items.Balgrahmen+" - "+snap.items.BalgrahmenAlpha+" - "+snap.items.Holz02)

    if(getAttribute("Balgrahmen", "value") === 9){
        return(
            <>
                <Balgrahmen_Holz_Edelweiss snap={snap}/>
            </>
        )
    }else{
        return(
            <>
                <Balgrahmen_Holz_Others snap={snap}/>
            </>
        )
    }
}

function Balgrahmen_Others(props){
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Base.glb")



    const Balgrahmen = useTexture(snap.items.Balgrahmen)
    const BalgrahmenAlpha = useTexture(snap.items.BalgrahmenAlpha)
    const Holz02 = useTexture(snap.items.Holz02)
    
    if(snap.items.Modell.includes(" GS")){
        return(
            <>
                <mesh geometry={nodes.Base_2.geometry} material={materials.Holz_02} material-map={Holz02}/>
                <mesh geometry={nodes.Base_9.geometry} material={materials.Balgrahmen_Streifen_Intarsien_Geschnitten} material-map={useTexture(snap.items.Intarsien)}  material-alphaMap={useTexture(snap.items.IntarsienAlpha)} material-alphaTest={0.8} material-opacity={1} transparent/>
            </>
        )
    }else if (snap.items.StreifenIntarsien) {
        return(
            <>
                <mesh geometry={nodes.Base_2.geometry} material={materials.Holz_02} material-map={Holz02}/>
            </>
        )
    }else if (snap.items.BalgrahmenIsPapierband) {
        return(
            <>
            <mesh geometry={nodes.Base_2.geometry} material={materials.Balgrahmen_Kunstleder_Papierband} material-color={snap.items.BalgrahmenKunstleder} />
            <mesh geometry={nodes.Base_4.geometry} material={materials.Balgrahmen_Papierband} material-map={Balgrahmen} material-alphaMap={BalgrahmenAlpha} material-alphaTest={0.5} material-opacity={1} transparent />
            </>
        )
    }else{
        return(
            <>
                <Balgrahmen_Holz snap={snap}/>
            </>
        )
    }

}

export default function Oergeli_Balgrahmen(props) {
    const snap = props.snap

    if(snap.items.Modell.includes(" Traditionell")){
        if(snap.items.Balgrahmen.includes("eichhorn")&&!snap.items.StreifenIntarsien){
            return (
                <>
                    <Breites_Lederband snap={snap}/>
                    <Balgrahmen_Eichhorn snap={snap}/>
                </>
            )
        }else  if(snap.items.Balgrahmen.includes("mauro")&&!snap.items.StreifenIntarsien){
            return (
                <>
                    <Breites_Lederband snap={snap}/>
                    <Balgrahmen_Mauro snap={snap}/>
                </>
            )
        }else{
            return (
                <><Balgrahmen_Others snap={snap}/></>
            )
        }
    }else{
        return (
            <><Balgrahmen_Others snap={snap}/></>
        )
    }
  }