import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"
import  { getAttribute } from "../spezifications/Spezification_Functions.js"

export default function Oergeli_Base(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Base.glb")
  return (
    <>
      <group>
        <mesh geometry={nodes.Base_11.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
        <mesh geometry={nodes.Base_12.geometry} material={materials.Holz_02} material-map={useTexture(snap.items.Holz02)} />
        <mesh geometry={nodes.Base_1.geometry} material={materials.Balgrahmen_Kunstleder} material-color={snap.items.BalgrahmenKunstleder} />
        <mesh geometry={nodes.Base_6.geometry} material={materials.Knoepfe} />
        <mesh geometry={nodes.Base_7.geometry} material={materials.Raster} />
        <mesh geometry={nodes.Base_8.geometry} material={materials.Metal} />
      </group>
    </>
  )
}
  ////////////////////////
  