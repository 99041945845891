import { StyleSheet, Text, View } from "@react-pdf/renderer"

const borderColor = "#737373"
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    marginTop: 32,
    paddingTop: 4,
    color: borderColor,
  },
  left: {
    flex: 1,
  },
  right: {
    
  },
  rightbold: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
})

function Footer() {
  const Br = () => "\n";
  return (
    <View style={styles.container}>
      <Text style={styles.left}>
      <Br /><Br /><Br />
      WIR	<Br />
      MACHEN<Br />
      SCHWYZERÖRGELI

      </Text>
      <View>
        <Text
          style={styles.rightbold}
        >
          ott-oergeli GmbH<Br />
        </Text>
        <Text
          style={styles.right}
        >
          Brentenstrasse 49<Br />
          CH-8842 Unteriberg<Br />
          +41 55 414 16 80<Br />
          kontakt@ott-oergeli.ch<Br />
          www.ott-oergeli.ch<Br />
        </Text>
      </View>
    </View>
  )
}

export default Footer
