import version0001 from "./version_0001.js"
import version0002 from "./version_0002.js"

export const id_version = "0002"

export default function getVersionList(versionString){
  //if input empty
  var version_pointer = -1;
  if (typeof versionString !== 'undefined') {
    version_pointer = versions.findIndex(item => item.version === versionString)
  }
  if(version_pointer==-1){
    version_pointer=versions.findIndex(item => item.version === id_version)
  }
  if(version_pointer==-1){
    version_pointer=0
    console.log("getVersionList: Error - no matching version was found")
  }


  return(versions[version_pointer].list)
}



const versions = [
    {
      version: "0001",
      list: version0001,
    },
    {
      version: "0002",
      list: version0002,
    },
  ]

  