const Balgrahmen_Kunstleder = [
  {
    id: 1,
    title: "dunkelbraun",
    color: "#382018",
    color_thumbnail: "#40251b",
  },
  {
    id: 2,
    title: "dunkelrot",
    color: "#562a2d",
    color_thumbnail: "#6b3236",
  },

  {
    id: 3,
    title: "dunkelgrün",
    color: "#233025",
    color_thumbnail: "#2d4531",
  },
  {
    id: 4,
    title: "dunkelblau",
    color: "#0b122d",
    color_thumbnail: "#131e4a",
  },
  {
    id: 5,
    title: "schwarz",
    color: "#090706",
    color_thumbnail: "#090706",
  },
]
export default Balgrahmen_Kunstleder
