//8! label is needed because it is a text input
const Knöpfe = [
  {
    id: 1,
    title: "Teflon rund",
    label: "Teflon rund",
    value: "Teflon rund",
    color: "#3f4b3c",
    price: 0,
  },
  {
    id: 2,
    title: "Teflon halbrund",
    label: "Teflon halbrund",
    value: "Teflon halbrund",
    color: "#000000",
    price: 0,
  },
]
export default Knöpfe
