import { StyleSheet, Text, View, Image, Link } from "@react-pdf/renderer"
const borderColor = "#d1d1d1"
const styles = StyleSheet.create({
  container: {},
  bold: {
    fontSize: 11,
  },
  body: {},
  qrcode: {
    width: "100pt",
    height: "100pt",
    minWidth: 100,
  },
  qrcontainter: {
    width: "100%",
    height: 100,
    marginTop: 15,

    display: "flex",
    flexDirection: "row",

    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
  line: {
    width: "100%",
    height: 0,
    borderTop: "1px solid #999",
  },
  qrcontainterLeft: {
    height: 98,
    width: 98,

    minWidth: 100,
  },
  qrcontainterRight: {
    marginTop: 35,
    marginLeft: 20,
    height: 100,
    display: "realtive",
    textDecoration: "none",
    color: "black",
  },
  preview: {
    marginTop: 15,
    marginBottom: 15,
    width: "100%",
    display: "realtive",
    textAlign: "center",
  },
})

function Body(props) {
  const text =
    props.formType === "proposal"
      ? "We propose hereby to furnish labor, material, equipment, and insuranace necessary for the completion of:"
      : "Specifications for Work Completed"

  //alert(qrcodesource)
  //<Text> Alternativ können Sie den Link benutzen:</Text>
  //<Link src={url}>{url}</Link>
  return (
    <View style={styles.container}>
      <View>
        <Image style={styles.preview} src={props.previewsource} />
      </View>
      <View style={styles.qrcontainter}>
        <View style={styles.qrcontainterLeft}>
          <Image style={styles.qrcode} src={props.qrcodesource} />
        </View>
        <View style={styles.qrcontainterRight}>
          <Text> Eine interaktive Version kannst du mit dem QR-Code ansehen.</Text>
          <Text> Diese Visualisierung entspricht nicht dem effektivem Produkt.</Text>
        </View>
      </View>
    </View>
  )
}

export default Body

