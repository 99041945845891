const version = [
  {
    title: "Modell_Kategorie",
    list: [
      "EDGAR OTT / EGGI",
      "EDGAR OTT TE",
      "EDGAR OTT TN",
      "EDGAR OTT GS",
      "EDGAR OTT Traditionell"
    ],
  },
  {
    title: "Modell",
    list: [
      "EGGI 2",
      "EGGI 3",
      "EDGAR OTT 2",
      "EDGAR OTT 3",
      "EDGAR OTT TN 2",
      "EDGAR OTT TN 3",
      "EDGAR OTT TN W",
      "EDGAR OTT TE 2",
      "EDGAR OTT TE 3",
      "EDGAR OTT TE W",
      "EDGAR OTT GS 2",
      "EDGAR OTT GS 3",
      "EDGAR OTT GS W",
      "EDGAR OTT GS HW",
      "EDGAR OTT Traditionell 2",
      "EDGAR OTT Traditionell 3",
      "EDGAR OTT Traditionell W",
    ],
  },
  {
    title: "Holz",
    list: [
      "Ahorn",
      "Kirschbaum",
      "Birnbaum",
      "Nussbaum",
      "Schwarznuss",
      "Schwarznuss und Ahorn",
    ],
  },
  {
    title: "Griffbrett",
    list: [
      "Kunstharz Lava sepiabraun",
      "Kunstharz braun Nussbaum",
      "Kunstharz Lava schwarz",
      "Kunstharz Ceramico rost",
      "Celluloid schwarz", 
      "Celluloid grün-gold maser",
      "Celluloid braun maser",
      "Celluloid braun karo",
      "Celluloid gold karo",
      "Celluloid bronze karo",
      "Celluloid rot karo",
      "Celluloid grau",
    ],
  },
  {
    title: "Intarsien",
    list: [
      "Karo Ahorn 9",
      "Karo Nuss 10",
      "Typ Eichhorn 4",
      "Mäander",
      "Mosaik 120",
      "Schweizerkreuz",
      "Typ Eichhorn rot 212",
      "Rosen",
      "Drachen / Schwäne",
      "Enten",
      "x-blau 1",
      "x-grün 2",
    ],
  },
  {
    title: "Balgrahmen",
    list: [
      "Papierband Nussbaumer",
      "Papierband Salvisberg",
      "Papierband Traditionell 1",
      "Papierband Traditionell 2",
      "Papierband Traditionell 3 Primeli",
      "Intarsie Typ Eichhorn schräg",
      "Intarsie Typ Eichhorn schräg alt",
      "Intarsie Mauro",
      "Laser Edelweiss",
      "Laser Alpaufzug",
      "Laser Eidgenuss",
      "Laser Heidi",
      "Laser Hirsche",
      "Streifen Intarsien",
      "TE Intarsie Typ Eichhorn schräg alt",
    ],
  },
  {
    title: "Balgfalten_Kunstleder",
    list: [
      "dunkelbraun",
      "dunkelrot",
      "dunkelgrün",
      "dunkelblau",
      "schwarz",
    ],
  },
  {
    title: "Balgpapier",
    list: [
      "Sternli und Möndli grün Ott",
      "Sternli grün Ott",
      "Sternli weiss Ott",
      "Sternli rot Ott",
      "Marmor weissgold 933",
      "Marmor weiss-grau 1570",
      "Marmor mittelgrau 959",
      "Marmor rot 900",
      "Edelweiss Ott",
      "Tassotti blau 465",
      "Tassotti rot 464",
      "Tassotti Kornblumen",
      "Tassotti Astern 473",
      "Carta florentina Blüten",
      "Tassotti Pusteblume 1573",
      "Tassotti Kirschblüte 1574",
      "Tassotti Viola 474",
      "Tassotti Goldblume 7024", 
      "Tassotti Blaublume 7026",
      "Tassotti Blumen Bouket 492",
      "Tassotti Danza di Fiori",
      "Tassotti Goldschnörkel 711",
      "Beige Ott",
    ],
  },
  {
    title: "Balg_Lederecken",
    list: [
      "beige",
      "rot 82",
      "dunkelrot",
      "dunkelblau",
      "dunkelgrün",
      "schwarz",
    ],
  },
  {
    title: "Balgrahmen_Kunstleder",
    list: [
      "dunkelbraun",
      "dunkelrot",
      "dunkelgrün",
      "dunkelblau",
      "schwarz",
    ],
  },
  {
    title: "Knöpfe",
    list: [
      "Teflon rund",
      "Teflon halbrund",
    ],
  },
  {
    title: "Verdeck",
    list: [
      "Typ Nussbaumer",
      "Natura", 
      "mit Edelweiss-Pin", 
      "mit Edelweiss-Gravur", 
      "Typ Eichhorn", 
      "GS", 
      "Ornament"
    ],
  },
  {
    title: "Röteln",
    list: [
      "ohne Röteln",
      "mit Röteln"
    ],
  },
  {
    title: "Beschläge",
    list: [
      "Typ Ott",
      "Typ Nussbaumer"
    ],
  },
]
export default version
