const Holz = [
  {
    id: 1,
    title: "Ahorn",
    titleHolz1: "Ahorn",
    titleHolz2: "Ahorn",
    thumbnail: "images/thumbnails/holz/ahorn.jpg",
    texture: "images/textures/holz/ahorn.jpg",
    Holz2: "images/textures/holz/ahorn.jpg",
    laseredHolz: "images/textures/holz_lasered/ahorn.jpg",
  },
  {
    id: 2,
    title: "Kirschbaum",
    titleHolz1: "Kirschbaum",
    titleHolz2: "Kirschbaum",
    thumbnail: "images/thumbnails/holz/kirschbaum.jpg",
    texture: "images/textures/holz/kirschbaum.jpg",
    Holz2: "images/textures/holz/kirschbaum.jpg",
    laseredHolz: "images/textures/holz_lasered/kirschbaum.jpg",
  },
  {
    id: 3,
    title: "Birnbaum",
    titleHolz1: "Birnbaum",
    titleHolz2: "Birnbaum",
    thumbnail: "images/thumbnails/holz/birnbaum.jpg",
    texture: "images/textures/holz/birnbaum.jpg",
    Holz2: "images/textures/holz/birnbaum.jpg",
    laseredHolz: "images/textures/holz_lasered/birnbaum.jpg",
  },
  {
    id: 4,
    title: "Nussbaum",
    titleHolz1: "Nussbaum",
    titleHolz2: "Nussbaum",
    thumbnail: "images/thumbnails/holz/nussbaum.jpg",
    texture: "images/textures/holz/nussbaum.jpg",
    Holz2: "images/textures/holz/nussbaum.jpg",
    laseredHolz: "images/textures/holz_lasered/nussbaum.jpg",
  },
  {
    id: 5,
    title: "Schwarznuss",
    titleHolz1: "Schwarznuss",
    titleHolz2: "Schwarznuss",
    thumbnail: "images/thumbnails/holz/schwarznuss.jpg",
    texture: "images/textures/holz/schwarznuss.jpg",
    Holz2: "images/textures/holz/schwarznuss.jpg",
    laseredHolz: "images/textures/holz_lasered/schwarznuss.jpg",
  },
  {
    id: 6,
    title: "Schwarznuss und Ahorn",
    titleHolz1: "Schwarznuss",
    titleHolz2: "Ahorn",
    thumbnail: "images/thumbnails/holz/ahorn_schwarznuss.jpg",
    texture: "images/textures/holz/schwarznuss.jpg",
    Holz2: "images/textures/holz/ahorn2.jpg",
    laseredHolz: "images/textures/holz_lasered/ahorn.jpg",
  },
]

export default Holz
