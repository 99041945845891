import { StyleSheet, Text, View } from "@react-pdf/renderer"
import { DateTime } from "luxon"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    marginTop: 0,
    paddingTop: 0,
    marginBottom: 20,
    paddingBottom: 20,
  },
  bold: {
    fontSize: 11,
  },
  h1: {
    fontSize: 24,
    fonttWeight: 600,
  },
  h2: {
    fontSize: 20,
  },
  left: {
    top: 0,
    flex: 1,
  },
  right: {
    // textAlign: 'right',
  },
  textpart: {
    marginBottom: 10,
  },
})

/* <View style={styles.textpart}>
          <Text>{formattedDate}</Text>
        </View>*/
function CostumerAdress(props) {
  if (props.hideformdata) {
    return <View style={styles.left}></View>
  } else {
    return (
      <View style={styles.left}>
        <View style={styles.textpart}>
          <Text>{props.formdata.salutation}</Text>
          <Text>
            {props.formdata.firstname} {props.formdata.lastname}
          </Text>
          <Text>
            {props.formdata.street} {props.formdata.streetnr}
          </Text>
          <Text>
            {props.formdata.plz} {props.formdata.place}
          </Text>
          <Text>{props.formdata.country}</Text>
        </View>
        <View style={styles.textpart}>
          <Text>{props.formdata.email}</Text>
          <Text>{props.formdata.phone}</Text>
          <Text>(bevorzugt per {props.formdata.preferredcommunication})</Text>
        </View>
      </View>
    )
  }
}

function Header(props) {
  const formattedDate = DateTime.now().setZone("Europe/Paris").toLocaleString()
  return (
    <View style={styles.container}>
      <CostumerAdress {...props} />
      <View style={styles.right}>
        <View style={styles.textpart}>
          <Text style={styles.bold}>ott-oergeli GmbH</Text>
          <Text>Brentenstrasse 49</Text>
          <Text>CH-8842 Unteriberg</Text>
        </View>
        <View style={styles.textpart}>
          <Text>+41 55 414 16 80</Text>
          <Text>kontakt@ott-oergeli.ch</Text>
          <Text>www.ott-oergeli.ch</Text>
        </View>
      </View>
    </View>
  )
}

export default Header
