const Griffbrett = [
  {
    id: 1,
    title: "Kunstharz Lava sepiabraun",
    thumbnail: "images/thumbnails/griffbrett/kunstharz_naturfaser_braun.jpg",
    texture: "images/textures/griffbrett/kunstharz_naturfaser_braun.jpg",
  },
  {
    id: 2,
    title: "Kunstharz braun Nussbaum",
    thumbnail: "images/thumbnails/griffbrett/kunstharz_braun_nussbaum.jpg",
    texture: "images/textures/griffbrett/kunstharz_braun_nussbaum.jpg",
  },
  {
    id: 3,
    title: "Kunstharz Lava schwarz",
    thumbnail: "images/thumbnails/griffbrett/kunstharz_lava_schwarz.jpg",
    texture: "images/textures/griffbrett/kunstharz_lava_schwarz.jpg",
  },
  {
    id: 4,
    title: "Kunstharz Ceramico rost",
    thumbnail: "images/thumbnails/griffbrett/kunstharz_ceramico_rost.jpg",
    texture: "images/textures/griffbrett/kunstharz_ceramico_rost.jpg",
  },
  {
    id: 5,
    title: "Celluloid schwarz",
    thumbnail: "images/thumbnails/griffbrett/celluloid_schwarz.jpg",
    texture: "images/textures/griffbrett/celluloid_schwarz.jpg",
  },
  {
    id: 6,
    title: "Celluloid grün-gold maser",
    thumbnail: "images/thumbnails/griffbrett/celluloid_gruen-gold_maser.jpg",
    texture: "images/textures/griffbrett/celluloid_gruen-gold_maser.jpg",
  },
  {
    id: 7,
    title: "Celluloid braun maser",
    thumbnail: "images/thumbnails/griffbrett/celluloid_braun_maser.jpg",
    texture: "images/textures/griffbrett/celluloid_braun_maser.jpg",
  },
  {
    id: 8,
    title: "Celluloid braun karo",
    thumbnail: "images/thumbnails/griffbrett/celluloid_braun_karo.jpg",
    texture: "images/textures/griffbrett/celluloid_braun_karo.jpg",
  },
  {
    id: 9,
    title: "Celluloid gold karo",
    thumbnail: "images/thumbnails/griffbrett/celluloid_gold_karo.jpg",
    texture: "images/textures/griffbrett/celluloid_gold_karo.jpg",
  },
  {
    id: 10,
    title: "Celluloid bronze karo",
    thumbnail: "images/thumbnails/griffbrett/celluloid_bronze_karo.jpg",
    texture: "images/textures/griffbrett/celluloid_bronze_karo.jpg",
  },
  {
    id: 11,
    title: "Celluloid rot karo",
    thumbnail: "images/thumbnails/griffbrett/celluloid_rot_karo.jpg",
    texture: "images/textures/griffbrett/celluloid_rot_karo.jpg",
  },
  {
    id: 12,
    title: "Celluloid grau",
    thumbnail: "images/thumbnails/griffbrett/celluloid_grau.jpg",
    texture: "images/textures/griffbrett/celluloid_grau.jpg",
  }
]

export default Griffbrett
