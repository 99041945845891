import React from "react"
import { Modal } from "react-bootstrap"
import Loading from "./gui/Loading.js"

//During the Mail is sending this Modal will appear
function BootstrapModal(props){
    return (
      <div className="info_thumbnail_containter">
       <Modal show={props.display} size="sm" centered>
          <Loading />
        </Modal>
      </div>
    )
}

export default BootstrapModal
