const Beschlaege = [
  {
    id: 1,
    title: "Typ Ott",
    label: "Typ Ott",
    value: "Typ Ott",
    price: 0,
  },
  {
    id: 2,
    title: "Typ Nussbaumer",
    label: "Typ Nussbaumer",
    value: "Typ Nussbaumer",
    price: 0,
  },

]
export default Beschlaege
