import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"

function Verdeck_Bassseite_Standard(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Verdeck_Basseite_Standard.glb')
    var Holz = useTexture(snap.items.Holz)
  
    if(snap.items.Modell.includes(" TE")||snap.items.Modell.includes(" GS")||snap.items.Modell.includes(" TN")){
      Holz = useTexture(snap.items.Griffbrett)
    }
  
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Verdeck_Basseite_Standard.geometry} material={materials.Holz_Loecher} material-map={Holz}/>
          <mesh geometry={nodes.Verdeck_Basseite_Standard_1.geometry} material={materials.Holz_01} material-map={Holz}/>
        </group>
      </>
    )
  }
  function Verdeck_Bassseite_Natura(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Verdeck_Basseite_Natura.glb')
    const Holz = useTexture(snap.items.Holz)
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Verdeck_Basseite_Natura.geometry} material={materials.Holz_Loecher} material-map={Holz}/>
          <mesh geometry={nodes.Verdeck_Basseite_Natura_1.geometry} material={materials.Holz_01} material-map={Holz}/>
        </group>
      </>
    )
  }
  
  function Oergeli_Verdeck_Bassseite_GS_TE(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Verdeck_Basseite_GS_TE.glb')
    var Holz = useTexture(snap.items.Holz)
  
    if(snap.items.Modell.includes(" TE")||snap.items.Modell.includes(" GS")||snap.items.Modell.includes(" TN")||snap.items.Modell.includes(" Traditionell")){
      Holz = useTexture(snap.items.Griffbrett)
    }
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Verdeck_Basseite_GS_TE.geometry} material={materials.Holz_Loecher} material-map={Holz}/>
          <mesh geometry={nodes.Verdeck_Basseite_GS_TE_1.geometry} material={materials.Holz_01} material-map={Holz}/>
        </group>
      </>
    )
  }
  
  export default function Oergeli_Verdeck_Bassseite(props) {
    const snap = props.snap
  
    if(snap.items.Verdeck=="Natura"){
      return (
        <Verdeck_Bassseite_Natura  snap={snap}/>
      )
    }else if(snap.items.Modell.includes(" TE")||snap.items.Modell.includes(" Traditionell")||snap.items.Modell.includes(" GS")){
      return (
        <Oergeli_Verdeck_Bassseite_GS_TE  snap={snap}/>
      )
    }else{
      return (
        <Verdeck_Bassseite_Standard  snap={snap}/>
      )
    }
  }