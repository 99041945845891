import React from "react"
import Modell from "./lists/Modell.js"
import Modell_Kategorie from "./lists/Modell_Kategorie"
import Holz from "./lists/Holz.js"
import Griffbrett from "./lists/Griffbrett.js"
import Intarsien from "./lists/Intarsien.js"
import Balgrahmen from "./lists/Balgrahmen.js"
import Balgfalten_Kunstleder from "./lists/Balgfalten_Kunstleder.js"
import Balgpapier from "./lists/Balgpapier.js"
import Balg_Lederecken from "./lists/Balg_Lederecken.js"
import Balgrahmen_Kunstleder from "./lists/Balgrahmen_Kunstleder.js"
import Knoepfe from "./lists/Knoepfe.js"
import Verdeck from "./lists/Verdeck.js"
import Roeteln from "./lists/Roeteln.js"
import Beschlaege from "./lists/Beschlaege.js"

////////the options for the configurator are saved/linked here////////
const id_spezifications = [
  {
    id: 1,
    title: "Modell_Kategorie",
    label: "Modell Kategorie",
    padding: 2,
    value: 1,
    type: "text",
    list: Modell_Kategorie,
    price: 0,
    specification: "",
  },
  {
    id: 2,
    title: "Modell",
    label: "Modell",
    padding: 2,
    value: 1,
    type: "text",
    color: "#3f4b3c",
    materialname: "Modell",
    list: Modell,
    price: 6110,
    specification: "EGGI 2",
  },
  {
    id: 3,
    title: "Holz",
    label: "Holz",
    padding: 2,
    value: 2,
    texture: "images/textures/holz/kirschbaum.jpg",
    type: "texture",
    color: "#3f4b3c",
    materialname: "Holz",
    list: Holz,
    price: 0,
    specification: "",
  },
  {
    id: 4,
    title: "Griffbrett",
    label: "Griffbrett",
    padding: 2,
    value: 1,
    texture: "images/textures/griffbrett/kunstharz_naturfaser_braun.jpg",
    type: "texture",
    color: "#3f4b3c",
    materialname: "Griffbrett",
    list: Griffbrett,
    price: 0,
    specification: "",
  },
  {
    id: 5,
    padding: 2,
    title: "Intarsien",
    label: "Intarsien",
    value: 1,
    texture: "images/textures/intarsien/karo_ahorn_9.jpg",
    alpha: "images/textures/intarsien/alpha/karo_ahorn_9_alpha.jpg",
    type: "alphatexture",
    color: "#3f4b3c",
    materialname: "Intarsien",
    list: Intarsien,
    price: 0,
    specification: "",
  },
  {
    id: 6,
    padding: 2,
    title: "Balgrahmen",
    label: "Balgrahmen",
    value: 11,
    texture: "images/textures/balgrahmen/laser_eidgenuss.jpg",
    alpha: "images/textures/balgrahmen/alpha/laser_eidgenuss_alpha.jpg",
    type: "alphatexture",
    color: "#3f4b3c",
    materialname: "Balgrahmen",
    list: Balgrahmen,
    price: 0,
    specification: "",
  },
  {
    id: 7,
    title: "Balgfalten_Kunstleder",
    label: "Balgfalten Kunstleder",
    padding: 2,
    value: 2,
    color: "#562a2d",
    type: "color",
    materialname: "BalgfaltenKunstleder",
    list: Balgfalten_Kunstleder,
    price: 0,
    specification: "",
  },
  {
    id: 8,
    title: "Balgpapier",
    label: "Balgpapier",
    padding: 2,
    value: 12,
    texture: "images/textures/balgpapier/tassotti_kornblumen.jpg",
    type: "texture",
    color: "#3f4b3c",
    materialname: "Balgpapier",
    list: Balgpapier,
    price: 0,
    specification: "",
  },
  {
    id: 9,
    title: "Balg_Lederecken",
    label: "Balg Lederecken",
    padding: 2,
    value: 2,
    color: "#912131",
    type: "color",
    materialname: "BalgLederecken",
    list: Balg_Lederecken,
    price: 0,
    specification: "",
  },
  {
    id: 10,
    title: "Balgrahmen_Kunstleder",
    label: "Balgrahmen Kunstleder",
    padding: 2,
    value: 2,
    color: "#562a2d",
    type: "color",
    materialname: "BalgrahmenKunstleder",
    list: Balgrahmen_Kunstleder,
    price: 0,
    specification: "",
  },
  {
    id: 11,
    padding: 2,
    title: "Knöpfe",
    label: "Knöpfe",
    value: 1,
    type: "text",
    color: "#3f4b3c",
    materialname: "Knoepfe",
    list: Knoepfe,
    price: 0,
    specification: "",
  },
  {
    id: 12,
    padding: 2,
    title: "Verdeck",
    label: "Verdeck",
    value: 1,
    type: "text",
    materialname: "Verdeck",
    list: Verdeck,
    price: 0,
    specification: "",
  },
  {
    id: 13,
    padding: 2,
    title: "Röteln",
    label: "Röteln",
    value: 2,
    type: "text",
    color: "#3f4b3c",
    materialname: "Roeteln",
    list: Roeteln,
    price: 0,
    specification: "",
  },
  {
    id: 14,
    padding: 2,
    title: "Beschläge",
    label: "Beschläge",
    value: 1,
    type: "text",
    color: "#3f4b3c",
    materialname: "Beschlaege",
    list: Beschlaege,
    price: 0,
    specification: "Typ Ott",
  },
]
export default id_spezifications
