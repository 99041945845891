/////////// END - THANK YOU PAGE ///////////
import React, {useEffect} from "react"
import {ResetContatcinfo} from "./spezifications/User_Contactinfo.js"

export default function App() {

  //can leave Page without alert
  useEffect(() => {
    window.onbeforeunload = null
  }, [])

  //go to https://ott-oergeli.ch
  function handleButton(e) {
    e.preventDefault()
    console.log("back to ott-oergeli.ch")
    window.location.href = "https://ott-oergeli.ch"
  }

  //reset Contactinfos - form has to refill for submitting again
  ResetContatcinfo()

  return (
    <>
      <div id="main_form_upper">
        <div className="form_container">
          <div className="form_width">
            <div className="form_title">Vielen Dank für Ihre Anfrage</div>
            Sie sollten in den nächsten Minuten eine automatisierte Email von uns bekommen.
            <br />
            <br />
            Hier gehts zurück zu unserer Webseite:
            <br />
            <button onClick={handleButton}>www.ott-oergeli.ch</button>
            <br />
            <br />
            Bei Fragen und andere Anliegen können Sie uns gerne kontaktieren:
            <br />
            <br />
            ott-oergeli GmbH <br />
            Brentenstrasse 49 <br />
            CH-8842 Unteriberg <br />
            +41 55 414 16 80 <br />
            kontakt@ott-oergeli.ch
          </div>
        </div>
      </div>
    </>
  )
}
