import { StyleSheet, Text, View, Image, Link } from "@react-pdf/renderer"
import Table from "./Table.js"
import Page1Header from "./Page1Header.js"
import Preview from "./Preview.js"
import { DateTime } from "luxon"

const styles = StyleSheet.create({
  container: {
    marginTop: 30,
    paddingTop: 0,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20,
  },
  body: {
    fontSize: 10,
  },
  qrcode: {
    width: "100pt",
    height: "100pt",
    minWidth: 100,
  },
  qrcontainter: {
    width: "100%",
    height: 100,
    marginTop: 30,

    display: "flex",
    flexDirection: "row",

    borderTop: 1,
    borderBottom: 1,
    borderColor: "#00000",
    BorderStyle: "solid",
  },
  line: {
    width: "100%",
    height: 0,
    borderTop: "1px solid #999",
  },
  qrcontainterLeft: {
    height: 100,
    width: 100,

    minWidth: 100,
  },
  qrcontainterRight: {
    marginTop: 30,
    marginLeft: 20,
    height: 100,
    display: "realtive",
  },
  preview: {
    marginTop: 50,
    marginBottom: 20,
    width: "100%",
    display: "realtive",
    textAlign: "center",
  },
})

function Body(props) {
  const Br = () => "\n";



  const formattedDate = DateTime.now().setZone("Europe/Paris").toLocaleString()
  return (
    <View style={styles.container}>
      <Page1Header formdata={props.formdata} hideformdata={props.hideformdata} />
      <Text style={styles.body}><Br /><Br /><Br /><Br />Unteriberg, {formattedDate}<Br /><Br /></Text>
      <Text style={styles.title}>ANGEBOT</Text>
      <Text style={styles.body}>Grüezi {props.formdata.salutation} {props.formdata.lastname}<Br /><Br /><Br /></Text>


      <Text style={styles.body}>
        Vielen Dank für Ihr Interesse an den vielfältigen Varianten der „ott-oergeli“.<Br /><Br />
      </Text>

      <Text style={styles.body}>
        Einzelne Details und Farben können von Ihrer Bilddatei abweichen. Deshalb empfehlen wir einen unverbindlichen Besuch in unserer Ausstellung.<Br /><Br />
      </Text>

      <Text style={styles.body}>
        Verfeinern Sie Ihr Bild von Ihrem Wunschörgeli, indem Sie uns für eine kompetente Beratung besuchen. Verschiedene Modelle zum Probespielen und das Originalmaterial zur Besichtigung, warten auf Sie.<Br /><Br />
      </Text>

      <Text style={styles.body}>
        Wir freuen uns, Sie bei uns in Unteriberg zu begrüssen. Besuche aber bitte voranmelden.<Br /><Br />
      </Text>
   
      <Text style={styles.body}>
        Für weitere Fragen stehen wir jederzeit gerne zu Ihrer Verfügung.<Br /><Br /><Br />
      </Text>
   
      <Text style={styles.body}>Freundliche Grüsse</Text>
      <Text style={styles.body}>ott-oergeli GmbH</Text>
     
    </View>
  )
}

export default Body
