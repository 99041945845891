const Verdeck = [
  {
    id: 1,
    title: "Typ Nussbaumer",
    value: "Typ Nussbaumer",
    label: "Typ Nussbaumer",
    price: 0,
  },
  {
    id: 2,
    title: "Natura",
    value: "Natura",
    label: "Natura",
    price: 0,
  },
  {
    id: 3,
    title: "mit Edelweiss-Pin",
    value: "mit Edelweiss-Pin",
    label: "mit Edelweiss-Pin",
    price: 0,
  },
  {
    id: 4,
    title: "mit Edelweiss-Gravur",
    value: "mit Edelweiss-Gravur",
    label: "mit Edelweiss-Gravur",
    price: 0,
  },
  {
    id: 5,
    title: "Typ Eichhorn",
    value: "Typ Eichhorn",
    label: "Typ Eichhorn",
    price: 0,
  },
  {
    id: 6,
    title: "GS",
    value: "GS",
    label: "GS",
    price: 0,
  },
  {
    id: 7,
    title: "Ornament",
    value: "Ornament",
    label: "Ornament",
    price: 0,
  },
]
export default Verdeck
