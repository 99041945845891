import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"

function Oergeli_Namenstafeln_Standard(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Namenstafel_Standard.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Namenstafel_Standard.geometry} material={materials.Name} />
          <mesh geometry={nodes.Namenstafel_Standard_1.geometry} material={materials.Name_Rand} />
          <mesh geometry={nodes.Namenstafel_Standard_2.geometry} material={materials.Metal} />
        </group>
      </>
    )
  }
  
  function Oergeli_Namenstafeln_TE(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Namenstafel_TE.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Namenstafel_TE.geometry} material={materials.Name} />
          <mesh geometry={nodes.Namenstafel_TE_1.geometry} material={materials.Name_Rand} />
          <mesh geometry={nodes.Namenstafel_TE_2.geometry} material={materials.Metal} />
        </group>
      </>
    )
  }
  
  function Oergeli_Namenstafeln_GS(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Namenstafel_GS.glb')
  
    var nameColor = "#41281a"
    if(snap.items.HolzIsSchwarznuss){
      nameColor = "#e2e4d7"
    }
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Namenstafel_GS} material-color={nameColor} material-alphaMap={useTexture("images/textures/gs_edgar_ott_mirrored.jpg")} material-alphaTest={0.3} material-opacity={1} transparent   />
        </group>
      </>
    )
  }
  
  export default function Oergeli_Namenstafeln(props) {
    const snap = props.snap
  
    if(snap.items.Modell.includes(" GS")){
      return (
        <Oergeli_Namenstafeln_GS snap={snap} />
      )
    }else if(snap.items.Modell.includes(" TE")||snap.items.Verdeck.includes("Typ Eichhorn")){
      return (
        <Oergeli_Namenstafeln_TE snap={snap} />
      )
    }else{
      return (
        <Oergeli_Namenstafeln_Standard snap={snap} />
      )
    }
  }
  