import React from "react"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"
import Form01 from "./Form_01.js"
import Form02 from "./Form_02.js"
import Form03 from "./Form_03.js"
import Form04 from "./Form_04.js"
import Form05 from "./Form_05.js"
import Form06 from "./Form_06.js"
import Preview from "./Form_10_Preview.js"

///////////Here the Routes are set up for the hole application///////////
export default function form(props) {
  return (
      <Routes>
        <Route key="home2" path="*" element={<Form01 />} />  
        <Route key="home" path="/" element={<Form01 />} />
        <Route key="02" path="/02" element={<Form02 />} />
        <Route key="03" path="/03" element={<Form03 />} />
        <Route key="04" path="/04" element={<Form04 />} />
        <Route key="05" path="/05" element={<Form05 />} />
        <Route key="vielen_dank" path="/vielen_dank" element={<Form06 />} />
        <Route key="preview" path="/preview" element={<Preview />} />
      </Routes>
  )
}
