const Gravur = [
  {
    id: 1,
    title: "Karo Ahorn 9",
    thumbnail: "images/thumbnails/intarsien/karo_ahorn_9.jpg",
    alpha: "images/textures/intarsien/alpha/karo_ahorn_9_alpha.jpg",
    texture: "images/textures/intarsien/karo_ahorn_9.jpg",
  },
  {
    id: 2,
    title: "Karo Nuss 10",
    thumbnail: "images/thumbnails/intarsien/karo_nuss_10.jpg",
    alpha: "images/textures/intarsien/alpha/karo_nuss_10_alpha.jpg",
    texture: "images/textures/intarsien/karo_nuss_10.jpg",
  },
  {
    id: 3,
    title: "Typ Eichhorn 4",
    thumbnail: "images/thumbnails/intarsien/typ_eichhorn_4.jpg",
    alpha: "images/textures/intarsien/alpha/typ_eichhorn_4_alpha.jpg",
    texture: "images/textures/intarsien/typ_eichhorn_4.jpg",
  },
  {
    id: 4,
    title: "Mäander",
    thumbnail: "images/thumbnails/intarsien/maeander_202.jpg",
    alpha: "images/textures/intarsien/alpha/maeander_202_alpha.jpg",
    texture: "images/textures/intarsien/maeander_202.jpg",
  },
  {
    id: 5,
    title: "Mosaik 120",
    thumbnail: "images/thumbnails/intarsien/masaik_120.jpg",
    alpha: "images/textures/intarsien/alpha/masaik_120_alpha.jpg",
    texture: "images/textures/intarsien/masaik_120.jpg",
  },
  {
    id: 6,
    title: "Schweizerkreuz",
    thumbnail: "images/thumbnails/intarsien/schweizerkreuz.jpg",
    alpha: "images/textures/intarsien/alpha/schweizerkreuz_alpha.jpg",
    texture: "images/textures/intarsien/schweizerkreuz.jpg",
  },
  {
    id: 7,
    title: "Typ Eichhorn rot 212",
    thumbnail: "images/thumbnails/intarsien/typ_eichhorn_rot_212.jpg",
    alpha: "images/textures/intarsien/alpha/typ_eichhorn_rot_212_alpha.jpg",
    texture: "images/textures/intarsien/typ_eichhorn_rot_212.jpg",
  },
  {
    id: 8,
    title: "Rosen",
    thumbnail: "images/thumbnails/intarsien/rosen.jpg",
    alpha: "images/textures/intarsien/alpha/rosen_alpha.jpg",
    texture: "images/textures/intarsien/rosen.jpg",
  },
  {
    id: 9,
    title: "Drachen / Schwäne",
    thumbnail: "images/thumbnails/intarsien/drachen_schwaene_5.jpg",
    alpha: "images/textures/intarsien/alpha/drachen_schwaene_5_alpha.jpg",
    texture: "images/textures/intarsien/drachen_schwaene_5.jpg",
  },
  {
    id: 10,
    title: "Enten",
    thumbnail: "images/thumbnails/intarsien/enten.jpg",
    alpha: "images/textures/intarsien/alpha/enten_alpha.jpg",
    texture: "images/textures/intarsien/enten.jpg",
  },
  {
    id: 11,
    title: "x-blau 1",
    thumbnail: "images/thumbnails/intarsien/x-blau_1.jpg",
    alpha: "images/textures/intarsien/alpha/x-blau_1_alpha.jpg",
    texture: "images/textures/intarsien/x-blau_1.jpg",
  },
  {
    id: 12,
    title: "x-grün 2",
    thumbnail: "images/thumbnails/intarsien/x-gruen_2.jpg",
    alpha: "images/textures/intarsien/alpha/x-gruen_2_alpha.jpg",
    texture: "images/textures/intarsien/x-gruen_2.jpg",
  },
]

export default Gravur
