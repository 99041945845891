import { StyleSheet, Text, View, Image } from "@react-pdf/renderer"
import { DateTime } from "luxon"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  bold: {
    fontSize: 11,
  },
  h1: {
    fontSize: 24,
    fontWeight: 600,
  },
  h2: {
    fontSize: 20,
  },
  left: {
    flex: 1,
    textAlign: "center",
  },
  right: {
    right: 0,
  },
  image: {
    width: 100,
    marginLeft: 415,
  },
})

function Header() {
  return (
    <View style={styles.container}>
      <View style={styles.right}>
        <Image style={styles.image} src="/images/logo.png" />
      </View>
    </View>
  )
}

export default Header
