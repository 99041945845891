/////////// Popup with the Option to Download the PDF ///////////
import React from "react"
import { Modal } from "react-bootstrap"
import QRCode from "./PDF_Generator.js"
import { getUrl } from "./spezifications/Spezification_Functions.js"
import UserData from "./spezifications/User_Contactinfo.js"

class BootstrapModal extends React.Component {
  constructor() {
    super()
    this.state = {
      showHide: false,
    }
  }

  handleModalShow() {
    this.setState({
      showHide: true,
    })
  }

  handleModalHide() {
    this.setState({
      showHide: false,
    })
  }

  render() {
    return (
      <div className="info_thumbnail_containter">
        <button className="auto_button_width" onClick={() => this.handleModalShow()}>
          PDF Download
        </button>
        <Modal show={this.state.showHide} onHide={() => this.handleModalHide()} size="sm" centered>
          <Modal.Header closeButton onClick={() => this.handleModalHide()}>
            <Modal.Title>PDF Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Vielen Dank für das Interesse.
            <br />
            <br />
            
            <QRCode
              text="PDF Download"
              waittext="bitte warten"
              formdata={UserData}
              url={getUrl()}
              hideformdata={true}
            />
            <br />
            (Es kann bis zu 10 Sekunden gehen, bis Ihr PDF fertig generiert ist)

            <br />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default BootstrapModal
