/////////// PDF GENERATOR: DOWNLOAD BUTTON OR SEND MAIL BUTTON///////////
import React, { useState, useEffect, Suspense } from "react"
import QRCode from "qrcode.react"
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer"
import { getPreviewUrl } from "./spezifications/Spezification_Functions.js"
import Pdf from "./pdf/Pdf.js"
import Viewport from "./Viewport.js"
import Form_05_Modal_SendMail from "./Form_05_Modal_SendMail.js"
import { useNavigate } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";

//////// the pdf and qr code are generated here
export default function QrCode(props) {
  const [url, setUrl] = useState("")
  const [PreviewUrl, setPreviewUrl] = useState("")
  const [MailtoMyself, setMailtoMyself] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      GeneratePreview()
    }, 1000)
  }, [])
 

  //////// an image is generated from the canvas ////////
  function GeneratePreview() {
    try {
      var canvasPreview = document.getElementById("viewport_image").firstChild
      var pngUrlPreview = canvasPreview
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")
      setPreviewUrl(pngUrlPreview)
      if(MailtoMyself){
        console.log("EEEEEMailsfdf")
        setMailtoMyself(false)
        SendToMyself(canvasPreview.toDataURL("image/png"))
      }
    } catch {
      console.log("Image from Viewport is failed")
    }
  }

  useEffect(() => {
    const canvas = document.getElementById("qr-gen")
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
    setUrl(pngUrl)
  }, [])

  return (
    <>
      <div style={{ display: "none" }}>
        <QRCode id="qr-gen" value={getPreviewUrl()} size={290} level={"H"} includeMargin={true} />
      </div>
      <div style={{ position: "fixed", left: "-1000px" }}>
        <Viewport
          id="viewport_image"
          preserveDrawingBuffer="true"
          fov={25}
          background={["#cfcfcf"]}
          noIcon={true}
        />
      </div>

      <PdfGenerator
        qrcodesource={url}
        previewsource={PreviewUrl}
        text={props.text}
        waittext={props.waittext}
        formdata={props.formdata}
        hideformdata={props.hideformdata}
        SendMail={props.SendMail}
      />
    </>
  )
}

var captchaValue = ""
//////// Pdf is generated here ////////
function PdfGenerator(props) {
  const [disableSubmit,setDisableSubmit] = useState(true);
  const [isSending,setIsSending] = useState(false);

  function onCaptchaChange(value) {
    setDisableSubmit(value==null)
    console.log("Captcha value:", value)
    captchaValue=value
  }

  let navigate = useNavigate()
  console.log("SendMail: " + props.SendMail)
  if (props.SendMail) {
    return (
      <>
        <Form_05_Modal_SendMail display={isSending}/>
        <div className="captcha_container">
          <ReCAPTCHA
          sitekey="6Lf8ZqYeAAAAADn7OCVtDYC7-5i4TcSG2s-Ldll5"
          onChange={onCaptchaChange}/>
        </div>

        <BlobProvider
          document={
            <Pdf
              qrcodesource={props.qrcodesource}
              previewsource={props.previewsource}
              formdata={props.formdata}
              url={getPreviewUrl()}
              preisliste={props.preisliste}
              hideformdata={props.hideformdata}
            />
          }
        >
          {({ blob, url, loading, error }) => {
            async function SubmitwithBlob() {
              setIsSending(true)

              Submit(blob, props.formdata).then(
                () => {
                  navigate("/vielen_dank", { replace: true })
                }
              )   
            }

            return <button disabled={disableSubmit} onClick={SubmitwithBlob}> Email senden </button>
          }}
        </BlobProvider>
      </>
    )
  } else {
    return (
      <div>
        <PDFDownloadLink
          document={
            <Pdf
              qrcodesource={props.qrcodesource}
              previewsource={props.previewsource}
              formdata={props.formdata}
              url={getPreviewUrl()}
              preisliste={props.preisliste}
              hideformdata={props.hideformdata}
            />
          }
          fileName="Ott-Oergeli_Angebot.pdf"
          className="button"
        >
          {({ blob, url, loading, error }) => (loading ? props.waittext : props.text)}
        </PDFDownloadLink>
      </div>
    )
  }
}

//document={<PdfDocument qrcodesource={props.qrcodesource} formdata={props.formdata} />}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

//////send Mail
const Submit = async (blob, formdata) => {
  const Base64 = await blobToBase64(blob)
  const ShortBase64 = Base64.substring(28)

  //var url = 'http://localhost:8888/api/sendmail.php'
  var url = 'https://konfigurator.ott-oergeli.ch/api/sendmail.php'



  await fetch(url, {
    method: 'POST', 
    headers: {

    },
    body: JSON.stringify({
      pdfdata: ShortBase64,
      captchatoken: captchaValue,
      email: formdata["email"],
    })
  }).then(res => res.json())
  .then(response => {
    console.log(response)
    if(response["success"]){
      console.log("Submit - sent")
    }else{
      console.log("couldn't send mail - this is the error: "+response["message"])
    }
  }).catch((error) => {
    console.error(error);
  })
}


//////send Mail to myself
const SendToMyself = async (image) => {
  const ShortenImage = image.substring(22)
  console.log(ShortenImage)
  //var url = 'http://localhost:8888/api/sendmail_tomyself.php'
  var url = 'https://konfigurator.ott-oergeli.ch/api/sendmail_tomyself.php'

  await fetch(url, {
    method: 'POST', 
    headers: {

    },
    body: JSON.stringify({
      image: ShortenImage,
    })
  }).then(res => res.json())
  .then(response => {
    console.log(response)
    if(response["success"]){
      console.log("sent mail to myself")
    }else{
      console.log("couldn't send mail to myself - this is the error: "+response["message"])
    }
  }).catch((error) => {
    console.error(error);
  })
}

