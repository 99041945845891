/////////// USER FILL COMMENT AND PREFERED MEDIUM FOR CONTACT ///////////
import React, { useEffect, Suspense } from "react"
import { Link, useNavigate } from "react-router-dom"
import ToggleButton from "./gui/ToggleButton.js"
import UserData from "./spezifications/User_Contactinfo.js"


function handleChanges(event) {
  try {
    UserData.comment = event.target.value
    console.log(event.target.value)
  } catch {
    console.log("couldn't handle change: " + event)
  }
}

export default function App() {
  //
  useEffect(() => {
    window.onbeforeunload = confirmExit
    function confirmExit() {
      return "Ihre Änderugen werden nicht gespeichert"
    }
  }, [])

  let navigate = useNavigate()
  useEffect(() => {
    if(!UserData["isset"]){
      console.log("keine Kontaktangaben - zurück zu Konfigurator Home")
      navigate("/", { replace: true })
    }
  }, {})


  return (
    <>
      <div id="main_form_upper">
        <div className="form_container">
          <div className="form_width">
            <br />
            Wie wollen Sie lieber kontaktiert werden?
            <ToggleButton />
            <hr />
            <div className="form_title">Fragen und Wünsche</div>
            <textarea
              placeholder="Fragen und Wünsche bitte hier hinein schreiben."
              id="text"
              name="comment"
              cols="35"
              rows="4"
              onChange={handleChanges}
              defaultValue={UserData.comment}
            ></textarea>
            <br />
          </div>
        </div>
      </div>
      <div id="main_form_under">
        <div className="navigation_arrow">
          <Link key="03" to="/03">
            <img src="images/icons/arrow_left.png" alt="arrow_left" className="arrow_left" />
          </Link>
          <Link key="05" to="/05">
            <img src="images/icons/arrow_right.png" alt="right" className="arrow_right" />
          </Link>
        </div>
      </div>
    </>
  )
}
