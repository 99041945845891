const Balg_Lederecken = [
  {
    id: 1,
    title: "beige",
    color: "#c99763",
    color_thumbnail: "#d1a270",
  },
  {
    id: 2,
    title: "rot 82",
    color: "#912131",
    color_thumbnail: "#912131",
  },
  {
    id: 3,
    title: "dunkelrot",
    color: "#562a2d",
    color_thumbnail: "#6b3236",
  },
  {
    id: 4,
    title: "dunkelblau",
    color: "#0b122d",
    color_thumbnail: "#131e4a",
  },
  {
    id: 5,
    title: "dunkelgrün",
    color: "#233025",
    color_thumbnail: "#2d4531",
  },
  {
    id: 6,
    title: "schwarz",
    color: "#090706",
    color_thumbnail: "#090706",
  },
]
export default Balg_Lederecken
