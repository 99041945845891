//8! label is needed because it is a text input
const Röteln = [
  {
    id: 1,
    title: "mit Röteln",
    label: "mit Röteln",
    value: "mit Röteln",
    color: "#3f4b3c",
    price: 0,
  },
  {
    id: 2,
    title: "ohne Röteln",
    label: "ohne Röteln",
    value: "ohne Röteln",
    color: "#000000",
    price: 0,
  },
]
export default Röteln
