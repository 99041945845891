import React, { useState, useEffect } from "react"
import Select from "react-select"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { getAttribute } from "./spezifications/Spezification_Functions.js"
import { changeTexture, changeColor, changeText } from "./Viewport.js"
import { useForceUpdatePrice } from "./Form_01.js"


////// check if option is selected //////
function isSelected(value1, title) {
  var classname = "not_selected"
  if (value1 === getAttribute(title, "value")) {
    classname = "is_selected"
  }
  return classname
}

////// displays the price, if defined //////
function Pricetag(props) {
  const price = getAttribute(props.title, "price")
  if (price > 0) {
    return <div className="pricetag">+{price} CHF</div>
  } else {
    return ""
  }
}

function Pricetagbracket(price) {
  if (price > 0) {
    return " (+" + price + " CHF)"
  } else {
    return ""
  }
}

////// titel and options are generated //////
export default function Form_01_Elements(props) {
  var isvisible = true;
  if(props.items.length==1){
    isvisible = false;
  }
  
  useEffect(()=>{
    if(props.items.length==1){
      isvisible = false;
      
      const type = getAttribute(props.titel, "type")
      const currentitem = props.items[0];

      //forceUpdate()
      console.log("props")

     
      //props.updateTotal()
    }
  }, []) 
    
  
  if(props.titel === "Beschläge"){
    var current_modell = getAttribute("Modell", "list")[getAttribute("Modell", "value")-1].title;
    var current_intarsien = getAttribute("Balgrahmen", "list")[getAttribute("Balgrahmen", "value")-1].title;
    if(current_modell.includes(" HW")||!current_intarsien.includes("Papierband")){
      return(<>  </>)
    }
  }

  
  return (
    <>
      {isvisible && <>
          <div className="form_segment">
            <div className="form_title marginleft5px">
              {getAttribute(props.titel, "label")}
              <br />
            </div>
            <ElementTexture {...props} />
          </div>
          <hr className="separater" />
        </>
    }
    </>
  )
}

////// returns the description if available //////
function getDescription(title, value) {
  try {
    return getAttribute(title, "list")[value - 1].description
  } catch {
    return ""
  }
}

////// updates the options //////
function useForceUpdate() {
  useForceUpdatePrice()
  const [value, setValue] = useState(0) // integer state
  return () => setValue((value) => value + 1) // update the state to force render
}

////// depending on the type the options are generated //////
function ElementTexture(props) {
  const type = getAttribute(props.titel, "type")
  const forceUpdate = useForceUpdate()

  if (type === "texture"||type === "alphatexture"||type === "color") {
    return (
      <>
        <div className={props.titel}>
          {props.items.map((item) => 
            {
              try{
                const currentitem = getAttribute(props.titel, "list").find(list_item => list_item.title === item)
                
                if (type === "color"){
                  return(
                    <OverlayTrigger
                      placement="bottom"
                      key={"overlay" + currentitem.title + currentitem.id}
                      overlay={
                        <Tooltip>
                          {currentitem.title}
                          {Pricetagbracket(currentitem.price)}
                        </Tooltip>
                      }
                    >
                    <div
                      key={currentitem.id}
                      className={["thumbnail", isSelected(currentitem.id, props.titel)].join(" ")}
                      style={{ backgroundColor: currentitem.color_thumbnail }}
                      onClick={() => {
                        forceUpdate()
                        changeColor(props.titel, currentitem.id, getAttribute(props.titel, "materialname"), currentitem.color)
                        props.updateTotal()
                      }}
                    ></div>
                    </OverlayTrigger>)
                }else if (type === "texture") {
                   return(
                    <OverlayTrigger
                      placement="bottom"
                      key={"overlay" + currentitem.title + currentitem.id}
                      overlay={
                        <Tooltip>
                          {currentitem.title}
                          {Pricetagbracket(currentitem.price)}
                        </Tooltip>
                      }
                    >
                    <img
                      key={currentitem.title + currentitem.id}
                      src={currentitem.thumbnail}
                      alt={currentitem.label}
                      className={["thumbnail", isSelected(currentitem.id, props.titel)].join(" ")}
                      data-tip={currentitem.title + currentitem.id}
                      data-for={currentitem.title + currentitem.id}
                      onClick={() => {
                        forceUpdate()
                        changeTexture(
                          props.titel ,
                          currentitem.id,
                          getAttribute(props.titel, "materialname"),
                          currentitem.texture,
                          false,
                        )
                        props.updateTotal()
                      }}
                    ></img>
    
                    </OverlayTrigger>)
    
                }else if (type === "alphatexture") {
                  var thumbnail = currentitem.thumbnail
                  if(currentitem.title === "Streifen Intarsien"){
                    thumbnail = getAttribute("Intarsien", "list")[getAttribute("Intarsien", "value")-1].thumbnail
                  }
                  return(
                      <OverlayTrigger
                        placement="bottom"
                        key={"overlay" + currentitem.title + currentitem.id}
                        overlay={
                          <Tooltip>
                            {currentitem.title}
                            {Pricetagbracket(currentitem.price)}
                          </Tooltip>
                        }
                      >
                        
                        <img
                                key={currentitem.title + currentitem.id}
                                src={thumbnail}
                                alt={currentitem.label}
                                className={["thumbnail", isSelected(currentitem.id, props.titel)].join(" ")}
                                data-tip={currentitem.title + currentitem.id}
                                data-for={currentitem.title + currentitem.id}
                                onClick={() => {
                                  forceUpdate()
                                  changeTexture(
                                    props.titel ,
                                    currentitem.id,
                                    getAttribute(props.titel, "materialname"),
                                    currentitem.texture,
                                    true,
                                    currentitem.alpha
                                  )
                                  props.updateTotal()
                                }}
                              ></img>
                          
                      </OverlayTrigger>)
                }
                  
              
              }catch(error){
            
              }})
          }    
        </div>
        <Pricetag title={props.titel} />
      </>
    )
      


  } else if (type === "text") {
    const options = [];

    props.items.forEach(function(item, index, array){
      try{
      const currentitem=getAttribute(props.titel, "list").find(list_item => list_item.title === item);
      options.push(currentitem);
      }catch(error){}
    })
    options.push();

    var value = getAttribute(props.titel, "list")[getAttribute(props.titel, "value") - 1].value
    var label = getAttribute(props.titel, "list")[getAttribute(props.titel, "value") - 1].label
    return (
      <>
        <div className="description_model">
          {getDescription(props.titel, getAttribute(props.titel, "value"))}
        </div>
        <div className={"select-wrapper"}>
          <Select
            options={options}
            defaultValue={{ label: label, value: value }}
            className="react-select-container"
            and
            classNamePrefix="react-select"
            isSearchable={false}
            inputProps={{readOnly: true}}
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                text: "#3599B8",
                font: "#3599B8",
                primary25: "#eeeeee",
                primary: "#333333",
                neutral80: "black",
                color: "black",
              },
            })}
            onChange={(e) => {
              forceUpdate()
              changeText(
                props.titel,
                e.id,
                getAttribute(props.titel, "materialname"),
                getAttribute(props.titel, "list")[e.id - 1].value,
              )
              props.updateTotal()
            }}
          />
        </div>
        <Pricetag title={props.titel} />
      </>
    )
  } else {
    return <></>
  }
}


////////Das Thumbnail soll für Streifen Intarsien regelmässig aktualisiert werden////
function ElementTextureStreifen(props) {
  const currentitem = props.currentitem
  const thumbnail = getAttribute("Intarsien", "list")[getAttribute("Intarsien", "value")-1].thumbnail
 

  return(
    <OverlayTrigger
      placement="bottom"
      key={"overlay" + currentitem.title + currentitem.id}
      overlay={
        <Tooltip>
          {currentitem.title}
          {Pricetagbracket(currentitem.price)}
        </Tooltip>
      }
    >
      
    <img
            key={currentitem.title + currentitem.id}
            src={thumbnail}
            alt={currentitem.label}
            className={["thumbnail", isSelected(currentitem.id, props.titel)].join(" ")}
            data-tip={currentitem.title + currentitem.id}
            data-for={currentitem.title + currentitem.id}
            onClick={() => {
              forceUpdate()
              changeTexture(
                props.titel ,
                currentitem.id,
                getAttribute(props.titel, "materialname"),
                currentitem.texture,
                true,
                currentitem.alpha
              )
              props.updateTotal()
            }}
          ></img>
      </OverlayTrigger>
  )
}