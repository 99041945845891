import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"



 ////////////////////////////////////////////////////
 function Oergeli_Melodieseite_Celuloid_hinten_Standard(props) {
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Melodieseite_Celluloid_hinten_Standard.glb')
  return (
    <>
      <group>
        <mesh geometry={nodes.Oergeli.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Griffbrett)}/>
      </group>
    </>
  )
}
function Oergeli_Melodieseite_Celuloid_hinten_Wiener(props) {
  const snap = props.snap
  const { nodes, materials } = useGLTF('./assets/Melodieseite_Celluloid_hinten_Wiener.glb')
  return (
    <>
      <group>
        <mesh geometry={nodes.Oergeli.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Griffbrett)}/>
      </group>
    </>
  )
}

function Oergeli_Melodieseite_Celuloid_hinten(props) {
  const snap = props.snap

  if(snap.items.Griffbrett.includes("celluloid")){
    if(snap.items.Modell.includes(" HW")||snap.items.Modell.includes(" W")){
      return (
        < Oergeli_Melodieseite_Celuloid_hinten_Standard snap={snap}/>
      )
    }else{
      return (
        < Oergeli_Melodieseite_Celuloid_hinten_Wiener snap={snap}/>
      )
    }
  }else{
    return (
      <></>
    )
  }
 
}



function Melodieseite_Griff_Standard(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Melodieseite_Standard.glb")

  return (
    <>
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.Melodieseite_Standard.geometry} material={materials.Griffbrett}material-map={useTexture(snap.items.Griffbrett)}/>
      <mesh geometry={nodes.Melodieseite_Standard_1.geometry} material={materials.Holz_01}  material-map={useTexture(snap.items.Holz)}/>
      <mesh geometry={nodes.Melodieseite_Standard_2.geometry} material={materials.Knoepfe} />
      <mesh geometry={nodes.Melodieseite_Standard_3.geometry} material={materials.Metal} />
      <mesh geometry={nodes.Melodieseite_Standard_4.geometry} material={materials.Leder} />
      </group>
    </>
  )
}

function Melodieseite_Griff_Wiener(props) {
  const group = useRef()
  const snap = props.snap
  const { nodes, materials } = useGLTF("./assets/Melodieseite_Wiener.glb")

  return (
    <>
      <group ref={group} dispose={null}>
        <mesh geometry={nodes.Melodieseite_Wiener.geometry} material={materials.Griffbrett} material-map={useTexture(snap.items.Griffbrett)}/>
        <mesh geometry={nodes.Melodieseite_Wiener_1.geometry} material={materials.Holz_01}  material-map={useTexture(snap.items.Holz)}/>
        <mesh geometry={nodes.Melodieseite_Wiener_2.geometry} material={materials.Knoepfe}  />
        <mesh geometry={nodes.Melodieseite_Wiener_3.geometry} material={materials.Metal} />
        <mesh geometry={nodes.Melodieseite_Wiener_4.geometry} material={materials.Leder} />
      </group>
    </>
  )
}

export default function Oergeli_Griff_Melodieseite(props){
  const snap = props.snap

  if(snap.items.Modell.includes(" W")||snap.items.Modell.includes(" HW")){
    return (
      <>
        <Oergeli_Melodieseite_Celuloid_hinten snap={snap} />
        <Melodieseite_Griff_Wiener snap={snap} />
      </>
      
    )
  }else{
    return (
      <>
        <Oergeli_Melodieseite_Celuloid_hinten snap={snap} />
        <Melodieseite_Griff_Standard snap={snap} />
      </>
     
    )
  }
}
