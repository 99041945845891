import React, { useState } from "react"
import { ToggleButton } from "react-bootstrap"
import UserData from "../spezifications/User_Contactinfo.js"

const ButtonGroup = ({ children }) => {
  return <span className="btn-group">{children}</span>
}

export default function ToggleButtonExample() {
  const initalValue = UserData.preferredcommunication
  const [radioValue, setRadioValue] = useState(initalValue)
  const [checked, setChecked] = useState(true)

  const radios = [
    { name: "per Telefon", value: "Telefon" },
    { name: "per Email", value: "Email" },
  ]

  function handleSwitchChanges(value) {
    setRadioValue(value)
    try {
      UserData.preferredcommunication = value
      console.log(value)
    } catch {
      console.log("couldn't handle change: " + event)
    }
  }

  return (
    <>
      <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="outline-dark"
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => {
              handleSwitchChanges(e.currentTarget.value)
            }}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  )
}
