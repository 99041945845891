export function setLaseredHolz(newLaseredHolz) {
  for (var item in Balgrahmen) {
    if (Balgrahmen[item].laser) {
      Balgrahmen[item].link = newLaseredHolz
    }
  }
}

export function setIntarsiePreis(modell) {
  var preis = 420
  if(modell.includes(" TK")){
    preis = 0
  }
  for (var item in Balgrahmen) {
    if (Balgrahmen[item].title.includes("Intarsie")){
      Balgrahmen[item].price = preis
    }
  }
}



const Balgrahmen = [
  {
    id: 1,
    title: "Papierband Nussbaumer",
    thumbnail: "images/thumbnails/balgrahmen/papierband_typ_nussbaumer.jpg",
    alpha: "images/textures/balgrahmen/alpha/papierband_typ_nussbaumer_alpha.jpg",
    texture: "images/textures/balgrahmen/papierband_typ_nussbaumer.jpg",
    price: 0,
  },
  {
    id: 2,
    title: "Papierband Salvisberg",
    thumbnail: "images/thumbnails/balgrahmen/papierband_typ_salvisberg.jpg",
    alpha: "images/textures/balgrahmen/alpha/papierband_typ_salvisberg_alpha.jpg",
    texture: "images/textures/balgrahmen/papierband_typ_salvisberg.jpg",
    price: 0,
  },
  {
    id: 3,
    title: "Papierband Traditionell 1",
    thumbnail: "images/thumbnails/balgrahmen/papierband_traditionell_1.jpg",
    alpha: "images/textures/balgrahmen/alpha/papierband_traditionell_1_alpha.jpg",
    texture: "images/textures/balgrahmen/papierband_traditionell_1.jpg",
    price: 0,
  },
  {
    id: 4,
    title: "Papierband Traditionell 2",
    thumbnail: "images/thumbnails/balgrahmen/papierband_traditionell_2.jpg",
    alpha: "images/textures/balgrahmen/alpha/papierband_traditionell_2_alpha.jpg",
    texture: "images/textures/balgrahmen/papierband_traditionell_2.jpg",
    price: 0,
  },
  {
    id: 5,
    title: "Papierband Traditionell 3 Primeli",
    thumbnail: "images/thumbnails/balgrahmen/papierband_traditionell_3_primeli.jpg",
    alpha: "images/textures/balgrahmen/alpha/papierband_traditionell_3_primeli_alpha.jpg",
    texture: "images/textures/balgrahmen/papierband_traditionell_3_primeli.jpg",
    price: 0,
  },
  {
    id: 6,
    title: "Intarsie Typ Eichhorn schräg",
    thumbnail: "images/thumbnails/balgrahmen/balgrahmen_intarsie_typ_eichhorn_schraeg.jpg",
    alpha: "images/textures/balgrahmen/alpha/balgrahmen_intarsie_typ_eichhorn_schraeg_alpha.jpg",
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_typ_eichhorn_schraeg.jpg",
    price: 420, //setIntarsiePreis will set new Parameter
  },
  {
    id: 7,
    title: "Intarsie Typ Eichhorn schräg alt",
    thumbnail: "images/thumbnails/balgrahmen/balgrahmen_intarsie_typ_eichhorn_schraeg_alt.jpg",
    alpha:
      "images/textures/balgrahmen/alpha/balgrahmen_intarsie_typ_eichhorn_schraeg_alt_alpha.jpg",
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_typ_eichhorn_schraeg_alt.jpg",
    price: 420, //setIntarsiePreis will set new Parameter
  },
  {
    id: 8,
    title: "Intarsie Mauro",
    thumbnail: "images/thumbnails/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    alpha: "images/textures/balgrahmen/alpha/balgrahmen_intarsie_mauro_alpha.jpg",
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    price: 420, //setIntarsiePreis will set new Parameter
  },
  {
    id: 9,
    title: "Laser Edelweiss",
    thumbnail: "images/thumbnails/balgrahmen/laser_edelweiss.jpg",
    alpha: "images/textures/balgrahmen/alpha/laser_edelweiss_alpha.jpg",
    laser: true,
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    price: 420,
  },
  {
    id: 10,
    title: "Laser Alpaufzug",
    thumbnail: "images/thumbnails/balgrahmen/laser_alpaufzug.jpg",
    alpha: "images/textures/balgrahmen/alpha/laser_alpaufzug_alpha.jpg",
    laser: true,
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    price: 420,
  },
  {
    id: 11,
    title: "Laser Eidgenuss",
    thumbnail: "images/thumbnails/balgrahmen/laser_eidgenuss.jpg",
    alpha: "images/textures/balgrahmen/alpha/laser_eidgenuss_alpha.jpg",
    laser: true,
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    price: 420,
  },
  {
    id: 12,
    title: "Laser Heidi",
    thumbnail: "images/thumbnails/balgrahmen/laser_heidi.jpg",
    alpha: "images/textures/balgrahmen/alpha/laser_heidi_alpha.jpg",
    laser: true,
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    price: 420,
  },
  {
    id: 13,
    title: "Laser Hirsche",
    thumbnail: "images/thumbnails/balgrahmen/laser_hirsche.jpg",
    alpha: "images/textures/balgrahmen/alpha/laser_hirsche_alpha.jpg",
    laser: true,
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    price: 420,
  },
  {
    id: 14,
    title: "Streifen Intarsien",
    thumbnail: "Streifen Intarsien",
    alpha: "images/textures/balgrahmen/alpha/laser_hirsche_alpha.jpg",
    laser: false,
    texture: "images/textures/balgrahmen/balgrahmen_intarsie_mauro.jpg",
    price: 420, //setIntarsiePreis will set new Parameter
  },
  {
    id: 15,
    title: "TE Intarsie Typ Eichhorn schräg alt",
    thumbnail: "images/thumbnails/balgrahmen/balgrahmen_intarsie_typ_eichhorn_schraeg_alt.jpg",
    alpha: "images/textures/balgrahmen/alpha/te_balgrahmen_intarsie_typ_eichhorn_schraeg_alt_alpha.jpg",
    texture: "images/textures/balgrahmen/te_balgrahmen_intarsie_typ_eichhorn_schraeg_alt.jpg",
    price: 0, //setIntarsiePreis will set new Parameter
  },
]

export default Balgrahmen
