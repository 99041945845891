////////This functions are the interface between the app and the Spezification_List.js////////
import React from "react"
import id_spezifications from "./Spezifiction_List.js"
import { setLaseredHolz, setIntarsiePreis} from "./lists/Balgrahmen.js"
import versionList, {id_version} from "./versions/versions.js"




export default id_spezifications


//////// if there is an issue with the id, the website goes to standard id on the homepage ////////
function goHome(){
  //window.open(window.location.hostname, "_self");
}


//////// set a new value at a specific tite ////////
export function changevalue(title, value) {
  //two possibilities for value: position or title
  for (var i = 0; i < id_spezifications.length; i++) {
    if (id_spezifications[i].title === title) {
      if(typeof value === "string"){  
        try{
          //if it is an string it look up the matching item and gives back the position
          value = 1 + id_spezifications[i].list.findIndex(idSpezificationItem => idSpezificationItem.title === value)
        }catch{
          console.log("couldn't find position of: "+title+" - "+value)
        }
      }
      if(!(value > -1)){
        value = 0
      }

      if(value>id_spezifications[i].list.length){
        goHome()
      }
      try {
        //put in the value to the list
        id_spezifications[i].value = value
        id_spezifications[i].specification = id_spezifications[i].list[value - 1].title
      } catch {
        console.log("something is wrong in changevalue - impossible value?")
        goHome()
      }


      //make this just if there is a price attribute
      try {
        id_spezifications[i].price = id_spezifications[i].list[value - 1].price
      } catch {
        console.log("no price: " + title)
      }

      try {
        if (id_spezifications[i].type === "color") {
          id_spezifications[i].color = id_spezifications[i].list[value - 1].color
        } else if (id_spezifications[i].type === "texture") {
          if (id_spezifications[i].title === "Holz") {
            setLaseredHolz(id_spezifications[i].list[value - 1].laseredHolz)
            changevalue("Balgrahmen", getAttribute("Balgrahmen", "value"))
          }
          id_spezifications[i].texture = id_spezifications[i].list[value - 1].texture
        } else if (id_spezifications[i].type === "alphatexture") {
          id_spezifications[i].texture = id_spezifications[i].list[value - 1].texture
          id_spezifications[i].alpha = id_spezifications[i].list[value - 1].alpha
        } else if (id_spezifications[i].type === "text") {
          
          if(id_spezifications[i].title === "Modell"){
            setIntarsiePreis(id_spezifications[i].list[value - 1].title)
          }
        }
      } catch {
        console.log("something is wrong in changevalue - impossible value?")
        goHome()
      }
    }
  }
  window.location.hash = "#" + getCurrentId()
}

//////// get the current Id from the Spezification_List.js ////////
export function getCurrentId() {
  var id = id_version


  for (var i = 0; i < versionList().length; i++) {

    const SpecificationIndex = id_spezifications.findIndex(item => item.title === versionList()[i].title)

    if(SpecificationIndex==-1){
      id = id.concat("00")
    }else{
      const currentTitle = id_spezifications[SpecificationIndex].list[id_spezifications[SpecificationIndex].value-1].title
      
      const VersionIndex = versionList()[i].list.findIndex(item => item === currentTitle)
      id = id.concat(VersionIndex.toString().padStart(2, "0"))
    }
  }

  
  return id
}


//////// get a specific attribute from the Spezification_List.js ////////
export function getAttribute(title, attribute) {
  try {
    for (var i = 0; i < id_spezifications.length; i++) {
      if (id_spezifications[i].title === title) {
        return id_spezifications[i][attribute]
      }
    }
  } catch {
    console.log("couldn't find " + attribute + " in " + title)
  }
}

export function getTotalPrice() {
  var price = 0
  try {
    //console.log("total price: " + price)
    for (var i = 0; i < id_spezifications.length; i++) {
      if (id_spezifications[i].price > 0) {
        price = price + id_spezifications[i].price
      }
    }
  } catch {
    console.log("getTotalPrice() - Error")
  }
  return price
}

//////// get the URL with the correct ID - Startpage ////////
export function getUrl() {
  var id = window.location.hostname + "/#" + getCurrentId()
  return id
}

//////// get URL to see the Preview - used for the QR-Code ////////
export function getPreviewUrl() {
  var id = window.location.hostname + "/preview#" + getCurrentId()
  return id
}





//////// takes the URL and extract an id + change all the values to the new values from the id ////////
export function LoadIdFromUrl() {
  if (window.location.href.split("#").length > 1) {
    var CurrentId = window.location.href.split("#")[1]
    var CurrentVersion = CurrentId.substring(0, 4)

    try {
      const versionobject = versionList(CurrentVersion)

      console.log("VersionIndex is set!")
      console.log(versionobject)

      if (CurrentId.match(/^[0-9]+$/) != null && CurrentId.length > 0) {
        if (versionobject.length * 2 + 4 === CurrentId.length) {
          for (var i = 0; i < versionobject.length; i++) {

            const objectposition = parseInt(CurrentId.substring(i * 2 + 4, i * 2 + 6, 10), 10)
            if(versionobject[i].list.length>objectposition-1){
              changevalue(versionobject[i].title, versionobject[i].list[objectposition])
            }
          }
        }
      }
    } catch (error) {
      console.log("couldn't load versionobject!! :(")
      console.log(error)
    }
  }
}


//////// takes the var value and updates all parameters ////////
export function update() {
  //update everything to the current values from Spezification_List.js
  for (var i = 0; i < id_spezifications.length; i++) {
    changevalue(id_spezifications[i].title, id_spezifications[i].value)
  }
}
