/////////// KONFIGURATOR OPTIONS ///////////
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { getTotalPrice, getAttribute } from "./spezifications/Spezification_Functions.js"
import Form01Elements from "./Form_01_Elements.js"


////// update Total //////
export function useForceUpdatePrice() {
  const [value, setValue] = useState(0)
  return () => setValue((value) => value + 1)
}

////// define Total part //////
function Total() {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 500)
  
    return () => clearInterval(interval)
  }, [])

  //useForceUpdatePrice()

  return (
    <div className="form_segment">
      <div className="form_title marginleft5px">
        Total: {getTotalPrice()} CHF
        <br />
      </div>
    </div>
  )

  
}


////// define Total part //////
function FormElements() {
  console.log("Modell Kategorie: "+getAttribute("Modell_Kategorie", "value"))
  const currentModellKategorie=getAttribute("Modell_Kategorie", "list")[getAttribute("Modell_Kategorie", "value")-1].list
   
  return (
    <>
      { (currentModellKategorie.map(({label, items}) => (
        <>
          <Form01Elements key={"EdgarOtt_"+label} titel={label} items={items} updateTotal={useForceUpdatePrice()} />
        </>
      )))}
    </>
  )
}


////// define all the options //////
export default function App() {
  return (
    <>
      <div id="main_form_upper">
        <div className="form_container">
            <div className="form_width">
            <FormElements />
            <Total />
            </div>
          </div>
      </div>
      <div id="main_form_under">
        <div className="navigation_arrow">
          <Link key="modell" to="/modell">
            <img src="images/icons/arrow_left.png" alt="arrow_left" className="arrow_left" />
          </Link>
          <Link key="02" to="/02">
            <img src="images/icons/arrow_right.png" alt="arrow_right" className="arrow_right" />
          </Link>{" "}
        </div>
      </div>
    </>
  )
}

