import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"

export default function Oergeli_Balg(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Balg.glb")
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Balg.geometry} material={materials.Balgpapier} material-color={"#aaaaaa"} material-map={useTexture(snap.items.Balgpapier)}/>
          <mesh geometry={nodes.Balg_1.geometry} material={materials.Balgpapier_Weiss} />
          <mesh geometry={nodes.Balg_2.geometry} material={materials.Balgfalten_Kunstleder}  material-color={snap.items.BalgfaltenKunstleder}/>
          <mesh geometry={nodes.Balg_3.geometry} material={materials.Balg_Lederecken} material-color={snap.items.BalgLederecken}/>
        </group>
      </>
    )
  }