import { StyleSheet, Text, View, Image, Link, Font } from "@react-pdf/renderer"
import Table from "./Table.js"
import Page1Header from "./Page1Header.js"
import RobotoCondesedRegular from "../fonts/RobotoCondensed-Regular.ttf"
import RobotoCondesedBold from "../fonts/RobotoCondensed-Bold.ttf"
import IdSpezification, {
  getAttribute,
  getTotalPrice,
} from "../spezifications/Spezification_Functions.js"
import { DateTime } from "luxon"

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
})

Font.register({
  family: "Roboto",
  format: "truetype",
  fonts: [
    { src: RobotoCondesedRegular, fontWeight: "normal" },
    { src: RobotoCondesedBold, fontWeight: "bold" },
  ],
})

function PreiszuText(price) {
  if (price === 0 || typeof price == "undefined") {
    const new_price = ""
    return new_price
  } else {
    const new_price = price.toLocaleString("de-CH") + " CHF"
    return new_price
  }
}

const styles = StyleSheet.create({
  container: {
    fontSize: 10,
    marginTop: 10,
    paddingTop: 10,
  },
  subtitle: {
    backgroundColor: "#dddddd",
    width: "100%",
    marginTop: 8,
    marginBottom: 8,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    padding: 3,
    fontWeight: "bold",
  },
  title: {
    fontSize: 11,
    marginBottom: 20,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  leftText: {
    paddingRight: 10,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
    fontFamily: "Roboto",
    textTransform: "uppercase",
    textAlign: "right",
    fontWeight: "normal",
  },
  rightText: {
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
    fontFamily: "Roboto",
    textTransform: "uppercase",
    fontWeight: "normal",
  },
  price: {
    position: "absolute",
    right: 0,
    marginRight: 0,
    width: 60,
  },
  body: {
    fontFamily: "Roboto",
  },
  qrcode: {
    width: "100pt",
    height: "100pt",
    minWidth: 100,
  },
  qrcontainter: {
    width: "100%",
    height: 100,
    marginTop: 30,

    display: "flex",
    flexDirection: "row",

    borderTop: 1,
    borderBottom: 1,
    borderColor: "#00000",
    BorderStyle: "solid",

    fontSize: 5,
  },
  line: {
    width: "100%",
    height: 0,
    borderTop: "1px solid #999",
  },
  qrcontainterLeft: {
    height: 100,
    width: 100,

    minWidth: 100,
  },
  qrcontainterRight: {
    marginTop: 30,
    marginLeft: 20,
    height: 100,
    display: "realtive",
  },
  preview: {
    marginTop: 30,
    marginBottom: 20,
    width: "100%",
    display: "realtive",
    textAlign: "center",
  },
})

function name(varname) {
  if (varname === "") {
    return ""
  } else {
    return "Name"
  }
}

function roeteln(varname) {
  if (getAttribute("Modell", "specification").includes(" TN")||getAttribute("Modell", "specification").includes(" TE")) {
    return "Ja"
  } else {
    return "Nein"
  }
}

function Body(props) {
  const formattedDate = DateTime.now().setZone("Europe/Paris").toLocaleString()
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.leftText}>{name(props.formdata.firstname)}</Text>
        <Text style={styles.rightText}>
          {props.formdata.firstname} {props.formdata.lastname}
        </Text>
      </View>

      <Text style={styles.subtitle}>Bestellung</Text>
      <View style={styles.row}>
        <Text style={styles.leftText}>Sachbearbeiterin </Text>
        <Text style={styles.rightText}>Heidi Ott </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Datum Offerte </Text>
        <Text style={styles.rightText}>{formattedDate}</Text>
      </View>

      <Text style={styles.subtitle}>Modell</Text>
      <View style={styles.row}>
        <Text style={styles.leftText}>Modell</Text>
        <Text style={styles.rightText}>{getAttribute("Modell", "specification")}</Text>
        <Text style={styles.price}>{PreiszuText(getAttribute("Modell", "price"))}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Chöre</Text>
        <Text style={styles.rightText}>
          {getAttribute("Modell", "list")[getAttribute("Modell", "value") - 1].choere}
        </Text>
      </View>

      <Text style={styles.subtitle}>Holz</Text>
      <View style={styles.row}>
        <Text style={styles.leftText}>Holz </Text>
        <Text style={styles.rightText}>
          {getAttribute("Holz", "list")[getAttribute("Holz", "value") - 1].titleHolz1}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Intarsien</Text>
        <Text style={styles.rightText}>{getAttribute("Intarsien", "specification")}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Balgrahmenholz</Text>
        <Text style={styles.rightText}>
          {getAttribute("Holz", "list")[getAttribute("Holz", "value") - 1].titleHolz2}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Balgintarsien</Text>
        <Text style={styles.rightText}>{getAttribute("Balgrahmen", "specification")}</Text>
        <Text style={styles.price}>{PreiszuText(getAttribute("Balgrahmen", "price"))}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Belag Griffbrett</Text>
        <Text style={styles.rightText}>{getAttribute("Griffbrett", "specification")}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Bohrung Verdecke</Text>
        <Text style={styles.rightText}>{getAttribute("Verdeck", "specification")}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Röteln</Text>
        <Text style={styles.rightText}>{roeteln()}</Text>
      </View>

      <Text style={styles.subtitle}>Mechanik</Text>
      <View style={styles.row}>
        <Text style={styles.leftText}>Knöpfe </Text>
        <Text style={styles.rightText}>{getAttribute("Knöpfe", "specification")}</Text>
        <Text style={styles.price}>{PreiszuText(getAttribute("Knöpfe", "price"))}</Text>
      </View>

      <Text style={styles.subtitle}>Stimmen</Text>
      <View style={styles.row}>
        <Text style={styles.leftText}>Stimmen</Text>
        <Text style={styles.rightText}>
          {getAttribute("Modell", "list")[getAttribute("Modell", "value") - 1].stimmen}
        </Text>
      </View>

      <Text style={styles.subtitle}>Balg</Text>
      <View style={styles.row}>
        <Text style={styles.leftText}>Balgfalten</Text>
        <Text style={styles.rightText}>
          {getAttribute("Balgfalten_Kunstleder", "specification")}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Balgecken</Text>
        <Text style={styles.rightText}>{getAttribute("Balg_Lederecken", "specification")}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Balgrahmenleder</Text>
        <Text style={styles.rightText}>
          {getAttribute("Balgrahmen_Kunstleder", "specification")}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Balgpapier</Text>
        <Text style={styles.rightText}>{getAttribute("Balgpapier", "specification")}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Balgbeschläge</Text>
        <Text style={styles.rightText}>OTT</Text>
      </View>

      <Text style={styles.subtitle}>Spezielles & Preise</Text>
      <View style={styles.row}>
        <Text style={styles.leftText}>Etui</Text>
        <Text style={styles.rightText}>inkl. Premium-Rucksack</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.leftText}>Preis inkl. 8.1 % MWST</Text>
        <Text style={styles.rightText}></Text>
        <Text style={styles.price}>{PreiszuText(getTotalPrice())}</Text>
      </View>
    </View>
  )
}

export default Body
