const version = [
  {
    title: "Modell",
    list: [
      "EGGI 2",
      "EGGI 3",
      "EDGAR OTT 2",
      "EDGAR OTT 3"
    ],
  },
  {
    title: "Holz",
    list: [
      "Ahorn",
      "Kirschbaum",
      "Birnbaum",
      "Nussbaum",
      "Schwarznuss",
      "Schwarznuss und Ahorn",
    ],
  },
  {
    title: "Griffbrett",
    list: [
      "Kunstharz Lava sepiabraun",
      "Kunstharz braun Nussbaum",
      "Kunstharz Lava schwarz",
    ],
  },
  {
    title: "Intarsien",
    list: [
      "Karo Ahorn 9",
      "Karo Nuss 10",
      "Typ Eichhorn 4",
      "Mäander",
      "Mosaik 120",
      "Schweizerkreuz",
      "Typ Eichhorn rot 212",
      "Rosen",
      "Drachen / Schwäne",
      "Enten",
      "x-blau 1",
      "x-grün 2",
    ],
  },
  {
    title: "Balgrahmen",
    list: [
      "Papierband Nussbaumer",
      "Papierband Salvisberg",
      "Papierband Traditionell 1",
      "Papierband Traditionell 2",
      "Papierband Traditionell 3 Primeli",
      "Intarsie Typ Eichhorn schräg",
      "Intarsie Typ Eichhorn schräg alt",
      "Intarsie Mauro",
      "Laser Edelweiss",
      "Laser Alpaufzug",
      "Laser Eidgenuss",
      "Laser Heidi",
      "Laser Hirsche",
    ],
  },
  {
    title: "Balgfalten_Kunstleder",
    list: [
      "dunkelbraun",
      "dunkelrot",
      "dunkelgrün",
      "dunkelblau",
      "schwarz",
    ],
  },
  {
    title: "Balgpapier",
    list: [
      "Sternli und Möndli grün Ott",
      "Sternli grün Ott",
      "Sternli weiss Ott",
      "Sternli rot Ott",
      "Marmor weissgold 933",
      "Marmor weiss-grau 1570",
      "Marmor mittelgrau 959",
      "Marmor rot 900",
      "Edelweiss Ott",
      "Tassotti blau 465",
      "Tassotti rot 464",
      "Tassotti Kornblumen",
      "Tassotti Astern 473",
      "Carta florentina Blüten",
    ],
  },
  {
    title: "Balg_Lederecken",
    list: [
      "beige",
      "rot 82",
      "dunkelrot",
      "dunkelblau",
      "dunkelgrün",
      "schwarz",
    ],
  },
  {
    title: "Balgrahmen_Kunstleder",
    list: [
      "dunkelbraun",
      "dunkelrot",
      "dunkelgrün",
      "dunkelblau",
      "schwarz",
    ],
  },
  {
    title: "Knöpfe",
    list: [
      "Teflon rund",
      "Teflon halbrund",
    ],
  },
]
export default version
