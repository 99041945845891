import React from "react"

//////// no options for the user - only see the configuration
export default function Preview() {
  return (
    <div className="form_container">
      <div className="form_width">
        <div className="form_segment">
          <div className="form_title">
            Vielen Dank für Ihre Anfrage. Hier sehen Sie Ihre Konfiguration.
          </div>
        </div>
      </div>
    </div>
  )
}
