import React, { useState, useEffect } from "react";

import { Routes, Route, BrowserRouter, Navigate, Link } from "react-router-dom"


function ImagePreviewContainer(props) {
  return (
    <Link key={props.link} to={"/"+props.link+props.id}>
      <div class="modellfamily_item">
        <div class="modellfamily_image"><img src={props.src} alt="Logo" id="modell_preview_image" /></div>
        <div class="modellfamily_text">
          <div class="form_title">{props.title}</div>
          <div class="modellfamily_description">{props.description}</div>
        </div>
      </div>
    </Link>
  )
}



///////////Here the Routes are set up for the hole application///////////
export default function form(props) {
  var scale_factor = 1.2
  var height = 400
  if (props.intern){
    scale_factor = 0.9
    height = 760
  }



  const size = useWindowSize()
  //const aspectratio = 780/640
  const scaleratio_width = size.width/820
  const scaleratio_height = size.height/height

  const scaleratio = Math.min(scale_factor, scaleratio_height, scaleratio_width)

  const scale_styles = { 
    transform: `scale(${scaleratio})`,
    width: "100%",
    height: "100%",
  }
  const main_modellselection_container_adjust = { 
    height: `${height}px`,
  }

  return (
      <>
        <div style={scale_styles} >
          <div id="main_modellselection_container" style={main_modellselection_container_adjust} >
            <ImagePreviewContainer src="images/modelle_thumbnail/edgar_ott__eggi.jpg" title="EGGI / EDGAR OTT" description="" link="" id="#00020000010000100111010100000000"/>
            <ImagePreviewContainer src="images/modelle_thumbnail/edgar_ott_tn.jpg" title="EDGAR OTT Traditionell" description="Traditionelle Bauweise nach Kundenwunsch ergänzen" link="" id="#00020415000502000111010100040100"/>
            <ImagePreviewContainer src="images/modelle_thumbnail/edgar_ott_gs.jpg" title="EDGAR OTT GS" description="gestemmte Stimmen" link="" id="#00020310000402130111010100040000"/>
            {props.intern && (<ImagePreviewContainer src="images/modelle_thumbnail/edgar_ott_tn.jpg" title="EDGAR OTT TN" description="Traditionelle Bauweise nach Nussbaumer" link="" id="#00020204000502000111010100040100"/>)}
            {props.intern && (<ImagePreviewContainer src="images/modelle_thumbnail/edgar_ott_te.jpg" title="EDGAR OTT TE" description="Traditionelle Bauweise nach Eichorn" link="" id="#00020107000502000111010100040100"/>)}

          </div>
        </div>
      </>
  )
}


// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}