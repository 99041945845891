const id_spezifications = [
  {
    label: "Modell",
    items: ["EDGAR OTT TN 2", "EDGAR OTT TN 3", "EDGAR OTT TN W"]
  },
  {
    label: "Knöpfe",
    items: ["Teflon rund", "Teflon halbrund"]
  },
  {
    label: "Holz",
    items: ["Ahorn", "Kirschbaum", "Birnbaum", "Nussbaum", "Schwarznuss"]
  },
  {
    label: "Griffbrett",
    items: ["Celluloid schwarz", "Celluloid grau", "Celluloid grün-gold maser", "Celluloid braun maser", "Celluloid braun karo", "Celluloid gold karo", "Celluloid bronze karo", "Celluloid rot karo"]
  },
  {
    label: "Intarsien",
    items: ["Karo Ahorn 9", "Karo Nuss 10", "Typ Eichhorn 4", "Mäander", "Mosaik 120", "Schweizerkreuz", "Typ Eichhorn rot 212","Rosen", "Drachen / Schwäne", "Enten", "x-blau 1", "x-grün 2"]
  },
  {
    label: "Balgrahmen",
    items: ["Intarsie", "Papierband Nussbaumer", "Papierband Salvisberg", "Papierband Traditionell 1", "Papierband Traditionell 2", "Papierband Traditionell 3 Primeli", "Streifen Intarsien"]
  },
  {
    label: "Balgrahmen_Kunstleder",
    items: ["dunkelbraun", "dunkelrot", "dunkelgrün", "dunkelblau", "schwarz"]
  },
  {
    label: "Balgfalten_Kunstleder",
    items: [ "dunkelbraun", "dunkelrot", "dunkelgrün", "dunkelblau", "schwarz"]
  },
  {
    label: "Balg_Lederecken",
    items: ["beige", "rot 82", "dunkelrot", "dunkelblau", "dunkelgrün", "schwarz"]
  },
  {
    label: "Balgpapier",
    items: ["Sternli und Möndli grün Ott", "Sternli grün Ott", "Sternli weiss Ott", "Sternli rot Ott", "Beige Ott", "Marmor weissgold 933", "Marmor weiss-grau 1570", "Edelweiss Ott", "Tassotti blau 465", "Tassotti rot 464", "Tassotti Kornblumen", "Tassotti Astern 473", "Tassotti Blumen Bouket 492", "Tassotti Danza di Fiori", "Carta florentina Blüten", "Tassotti Pusteblume 1573", "Tassotti Viola 474", "Tassotti Goldblume 7024", "Tassotti Goldschnörkel 711"]
  },
]
export default id_spezifications
