import { Html } from "@react-three/drei"
import "./Loading_styles.css"
import LoaderElement from "./Loading.js"

////////Loader - working in Three.js////////
export default function Loader() {
  return (
    <Html center>
      <LoaderElement />
    </Html>
  )
}
