import {
  Document,
  Font,
  Page,
  StyleSheet,
  View,
  Text,
} from "@react-pdf/renderer"

import Page1 from "./Page1.js"
import PageOfferte from "./Page_Offerte.js"
import Footer from "./Footer.js"
import Header from "./Header.js"
import Preview from "./Preview.js"


const styles = StyleSheet.create({
  body: {
    fontFamily: 'Roboto',
    backgroundColor: "#ffffff",

    fontSize: 10,
    lineHeight: 0,
    padding: 40,
    height: "100vh",
  },
  top: {
    flex: 1,
  },
  h1: {
    fontSize: 32,
    marginBottom: 16,
  },
  addmargintop: {
    paddingTop: 100,
  },
})

Font.register({
  family: "Roboto",
  src: "/fonts/RobotoCondensed-Light.ttf",
})

Font.register({
  src: `/fonts/Roboto-Black.ttf`,
  family: "Roboto",
  fontWeight: "bold",
})




function offer(props) {
  //alert(props.url)

  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.top}>
          <Header />
          <Page1
            {...props}
          />
        </View>
        <View style={styles.bottom}>
          <Footer />
        </View>
      </Page>



      <Page style={styles.body} wrap>
        <View style={styles.top}>
          <Header />
          <View  style={styles.addmargintop}>
            <Preview {...props} />
          </View>
        </View>
        <View style={styles.bottom}>
          <Footer />
        </View>
      </Page>


      <Page style={styles.body} wrap>
        <View style={styles.top}>
          <Header />
          <PageOfferte {...props} />
        </View>
        <View style={styles.bottom}>
          <Footer />
        </View>
      </Page>

      <Commentpage {...props} />
    </Document>
  )
}

export default offer



function Commentpage(props) {
  if (props.hideformdata) {
    return <></>
  } else {
    return (
      <Page style={styles.body} wrap>
        <View style={styles.top}>
          <Header />
          <View style={styles.container}>
            <Text>Ihre Fragen und Wünsche:</Text>
            <Text>{props.formdata.comment}</Text>
          </View>
        </View>
        <View style={styles.bottom}>
          <Footer />
        </View>
      </Page>
    )
  }
}