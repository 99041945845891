import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"

export default function Beschlaege(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF("./assets/Beschlaege.glb")
  
    
    if(!snap.items.Modell.includes(" Traditionell") || !snap.items.Verdeck.includes("Nussbaumer")){
      return (
        <>
          <group>
            <mesh geometry={nodes.OUT_Beschlaege1.geometry} material={materials.Metal_Beschlagecken_Ott} />
          </group>
        </>
      )
    }else{
      if(snap.items.Modell.includes(" 3")){
        return (
          <>
            <group>
              <mesh geometry={nodes.OUT_Beschlaege1_1.geometry} material={materials.Metal_Beschlagecken_TypNussbaumer_2choerig} />
            </group>
          </>
        )
      }else{
        return (
          <>
            <group>
              <mesh geometry={nodes.OUT_Beschlaege1_2.geometry} material={materials.Metal_Beschlagecken_TypNussbaumer_3choerig} />
            </group>
          </>
        )
      }
    }
  }