const Balgpapier = [
  {
    id: 1,
    title: "Sternli und Möndli grün Ott",
    thumbnail: "images/thumbnails/balgpapier/sternli_und_moendli_gruen_ott.jpg",
    texture: "images/textures/balgpapier/sternli_und_moendli_gruen_ott.jpg",
  },
  {
    id: 2,
    title: "Sternli grün Ott",
    thumbnail: "images/thumbnails/balgpapier/sternli_gruen_ott.jpg",
    texture: "images/textures/balgpapier/sternli_gruen_ott.jpg",
  },
  {
    id: 3,
    title: "Sternli weiss Ott",
    thumbnail: "images/thumbnails/balgpapier/sternli_weiss_ott.jpg",
    texture: "images/textures/balgpapier/sternli_weiss_ott.jpg",
  },
  {
    id: 4,
    title: "Sternli rot Ott",
    thumbnail: "images/thumbnails/balgpapier/sternli_rot_ott.jpg",
    texture: "images/textures/balgpapier/sternli_rot_ott.jpg",
  },
  {
    id: 5,
    title: "Marmor weissgold 933",//Hier ist ein Fehler passier - war urspruenlich 933
    thumbnail: "images/thumbnails/balgpapier/marmor_weissgold_955.jpg",
    texture: "images/textures/balgpapier/marmor_weissgold_955.jpg",
  },
  {
    id: 6,
    title: "Marmor weiss-grau 1570",
    thumbnail: "images/thumbnails/balgpapier/marmor_weiss_grau_1570.jpg",
    texture: "images/textures/balgpapier/marmor_weiss_grau_1570.jpg",
  },
  {
    id: 7,
    title: "Marmor mittelgrau 959",
    thumbnail: "images/thumbnails/balgpapier/marmor_mittelgrau_959.jpg",
    texture: "images/textures/balgpapier/marmor_mittelgrau_959.jpg",
  },
  {
    id: 8,
    title: "Marmor rot 900",
    thumbnail: "images/thumbnails/balgpapier/marmor_rot_900.jpg",
    texture: "images/textures/balgpapier/marmor_rot_900.jpg",
  },
  {
    id: 9,
    title: "Edelweiss Ott",
    thumbnail: "images/thumbnails/balgpapier/edelweiss_ott.jpg",
    texture: "images/textures/balgpapier/edelweiss_ott.jpg",
  },
  {
    id: 10,
    title: "Tassotti blau 465",
    thumbnail: "images/thumbnails/balgpapier/tassotti_blau_465.jpg",
    texture: "images/textures/balgpapier/tassotti_blau_465.jpg",
  },
  {
    id: 11,
    title: "Tassotti rot 464",
    thumbnail: "images/thumbnails/balgpapier/tassotti_rot_464.jpg",
    texture: "images/textures/balgpapier/tassotti_rot_464.jpg",
  },
  {
    id: 12,
    title: "Tassotti Kornblumen",
    thumbnail: "images/thumbnails/balgpapier/tassotti_kornblumen.jpg",
    texture: "images/textures/balgpapier/tassotti_kornblumen.jpg",
  },
  {
    id: 13,
    title: "Tassotti Astern 473",
    thumbnail: "images/thumbnails/balgpapier/tassotti_astern_476.jpg",
    texture: "images/textures/balgpapier/tassotti_astern_476.jpg",
  },
  {
    id: 14,
    title: "Carta florentina Blüten",
    thumbnail: "images/thumbnails/balgpapier/carta_fiorentina_blueten.jpg",
    texture: "images/textures/balgpapier/carta_fiorentina_blueten.jpg",
  },
  {
    id: 15,
    title: "Tassotti Pusteblume 1573",
    thumbnail: "images/thumbnails/balgpapier/tassotti_pusteblume_1573.jpg",
    texture: "images/textures/balgpapier/tassotti_pusteblume_1573.jpg",
  },
  {
    id: 16,
    title: "Tassotti Kirschblüte 1574",
    thumbnail: "images/thumbnails/balgpapier/tassotti_kirschbluete_1574.jpg",
    texture: "images/textures/balgpapier/tassotti_kirschbluete_1574.jpg",
  },
  {
    id: 17,
    title: "Tassotti Viola 474",
    thumbnail: "images/thumbnails/balgpapier/tassotti_viola_474.jpg",
    texture: "images/textures/balgpapier/tassotti_viola_474.jpg",
  },
  {
    id: 18,
    title: "Tassotti Goldblume 7024",
    thumbnail: "images/thumbnails/balgpapier/tassotti_goldblume_7024.jpg",
    texture: "images/textures/balgpapier/tassotti_goldblume_7024.jpg",
  },
  {
    id: 19,
    title: "Tassotti Blaublume 7026",
    thumbnail: "images/thumbnails/balgpapier/tassotti_blaublume_7026.jpg",
    texture: "images/textures/balgpapier/tassotti_blaublume_7026.jpg",
  },
  {
    id: 20,
    title: "Tassotti Blumen Bouket 492",
    thumbnail: "images/thumbnails/balgpapier/tassotti_blumen_bouket_492.jpg",
    texture: "images/textures/balgpapier/tassotti_blumen_bouket_492.jpg",
  },
  {
    id: 21,
    title: "Tassotti Danza di Fiori",
    thumbnail: "images/thumbnails/balgpapier/tassotti_danza_di_fiori.jpg",
    texture: "images/textures/balgpapier/tassotti_danza_di_fiori.jpg",
  },
  {
    id: 22,
    title: "Tassotti Goldschnörkel 711",
    thumbnail: "images/thumbnails/balgpapier/tassotti_goldschnoerkel_711.jpg",
    texture: "images/textures/balgpapier/tassotti_goldschnoerkel_711.jpg",
  },
  {
    id: 23,
    title: "Beige Ott",
    thumbnail: "images/thumbnails/balgpapier/beige_ott.jpg",
    texture: "images/textures/balgpapier/beige_ott.jpg",
  }
]

export default Balgpapier
