import React, { useRef } from "react"
import {  useGLTF, useTexture } from "@react-three/drei"


///////////////////////Oergeli_Loecher_Melodieseite_vorne//////////////////////////
function Oergeli_Loecher_Melodieseite_vorne_Loecher(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Loecher_Melodieseite_vorne.glb')
  
    return (
      <>
        <group>
        <mesh geometry={nodes.Loecher_Melodieseite_vorne.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
        <mesh geometry={nodes.Loecher_Melodieseite_vorne_1.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
        </group>
      </>
    )
    //FROG
  }
  function Oergeli_Loecher_Melodieseite_vorne_keineLoecher(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/ohne_Loch_Melodieseite_vorne.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Holz_01}  material-map={useTexture(snap.items.Holz)} />
        </group>
      </>
    )
  }
  function Oergeli_Loecher_Melodieseite_vorne(props) {
    const snap = props.snap
  
    if(snap.items.Modell.includes(" TE")||snap.items.Modell.includes(" GS")||snap.items.Verdeck.includes("Typ Eichhorn")){
      return (
        <Oergeli_Loecher_Melodieseite_vorne_Loecher snap={snap} />
      )
    }else{
      return (
        <Oergeli_Loecher_Melodieseite_vorne_keineLoecher snap={snap} />
      )
    }
  }
  ///////////////////////Oergeli_Loecher_Melodieseite_hinten//////////////////////////
  
  function Oergeli_Loecher_Melodieseite_hinten_Loecher(props) {
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Loecher_Melodieseite_hinten.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Melodieseite_hinten.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)} />
          <mesh geometry={nodes.Loecher_Melodieseite_hinten_1.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)} />
        </group>
      </>
    )
  }
  function Oergeli_Loecher_Melodieseite_hinten_keineLoecher(props) {
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/ohne_Loch_Melodieseite_hinten.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
        </group>
      </>
    )
  }
  function Oergeli_Loecher_Melodieseite_hinten(props) {
    const snap = props.snap
  
    if(snap.items.Modell.includes(" GS")||snap.items.Modell.includes(" TE")){
      return (
        <Oergeli_Loecher_Melodieseite_hinten_Loecher snap={snap} />
      )
    }else{
      return (
        <Oergeli_Loecher_Melodieseite_hinten_keineLoecher snap={snap} />
      )
    }
  }
  
  
  ///////////////////
  
  
  ///////////////////////Oergeli_Loecher_Basseite_hinten//////////////////////////
  function Oergeli_Loecher_Basseite_1teilg_hinten(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Loecher_Bassseite_1teilig_hinten.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Bassseite_1teilig_hinten.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
          <mesh geometry={nodes.Loecher_Bassseite_1teilig_hinten_1.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
        </group>
      </>
    )
    //FROG
  }
  
  function Oergeli_Loecher_Basseite_2teilg_hinten(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Loecher_Bassseite_2teilig_hinten.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Bassseite_2teilig_hinten.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
          <mesh geometry={nodes.Loecher_Bassseite_2teilig_hinten_1.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
        </group>
      </>
    )
  }
  
  function Oergeli_Loecher_Basseite_Natura(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Loecher_Bassseite_hinten_Natura.glb')
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Bassseite_hinten_Natura.geometry} material={materials.Holz_01} material-map={useTexture(snap.items.Holz)}/>
          <mesh geometry={nodes.Loecher_Bassseite_hinten_Natura_1.geometry} material={materials.Holz_Loecher} material-map={useTexture(snap.items.Holz)}/>
        </group>
      </>
    )
  }
  
  function Oergeli_Loecher_Basseite_hinten(props) {
    const snap = props.snap
  
    if(snap.items.Modell.includes(" TE")||snap.items.Modell.includes(" TN")||snap.items.Modell.includes(" Traditionell")||snap.items.Modell.includes(" GS")){
      return (
        <Oergeli_Loecher_Basseite_2teilg_hinten snap={snap} />
      )
    }else if(snap.items.Verdeck=="Natura"){
      return (
        <Oergeli_Loecher_Basseite_Natura snap={snap} />
      )
    }else{
      return (
        <Oergeli_Loecher_Basseite_1teilg_hinten snap={snap} />
      )
    }
  }
  ////////////////////////////////////////////////////////////////////////////////
  
  ///////////////////////Oergeli_Loecher_Basseite_vorne//////////////////////////
  function Oergeli_Loecher_Basseite_vorne_Loecher(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Loecher_Bassseite_vorne.glb')
    const Holz = useTexture(snap.items.Holz)
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Bassseite_vorne.geometry} material={materials.Holz_Loecher} material-map={Holz}/>
          <mesh geometry={nodes.Loecher_Bassseite_vorne_1.geometry} material={materials.Holz_01} material-map={Holz}/>
        </group>
      </>
    )
  }
  function Oergeli_Loecher_Basseite_vorne_Loecher_TE(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/Loecher_Bassseite_TE_vorne.glb')
    const Holz = useTexture(snap.items.Holz)
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Bassseite_TE_vorne.geometry} material={materials.Holz_Loecher} material-map={Holz}/>
          <mesh geometry={nodes.Loecher_Bassseite_TE_vorne_1.geometry} material={materials.Holz_01} material-map={Holz}/>
        </group>
      </>
    )
  }
  function Oergeli_Loecher_Basseite_vorne_keineLoecher(props) {
    const group = useRef()
    const snap = props.snap
    const { nodes, materials } = useGLTF('./assets/ohne_Loch_Bassseite_vorne.glb')
    const Holz = useTexture(snap.items.Holz)
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Oergeli.geometry} material={materials.Holz_01} material-map={Holz}/>
        </group>
      </>
    )
  }
  function Oergeli_Loecher_Basseite_vorne(props) {
    const snap = props.snap
  
    if(snap.items.Modell.includes(" GS")){
      return (
        <Oergeli_Loecher_Basseite_vorne_Loecher snap={snap} />
      )
    }else if(snap.items.Modell.includes(" TE")||snap.items.Verdeck.includes("Typ Eichhorn")){
      return (
        <Oergeli_Loecher_Basseite_vorne_Loecher_TE snap={snap} />
      )
    }else{
      return (
        <Oergeli_Loecher_Basseite_vorne_keineLoecher snap={snap} />
      )
    }
  }
  ////////////////////////////////////////////////////////////////////////////////
  /*function Oergeli_Loecher_Basseite_nebenKnoepfe_Standard(props) {
    const group = useRef()
    const snap = useSnapshot(state)
    const { nodes, materials } = useGLTF('./assets/Loecher_Basseite_nebenKnoepfe_Standard.glb')
    const Griffbrett = useTexture(snap.items.Griffbrett)
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Basseite_nebenKnoepfe_Standard.geometry} material={materials.Holz_Loecher} material-map={Griffbrett}/>
          <mesh geometry={nodes.Loecher_Basseite_nebenKnoepfe_Standard_1.geometry} material={materials.Griffbrett} material-map={Griffbrett}/>
        </group>
      </>
    )
  }
  function Oergeli_Loecher_Basseite_nebenKnoepfe_Natura(props) {
    const group = useRef()
    const snap = useSnapshot(state)
    const { nodes, materials } = useGLTF('./assets/Loecher_Basseite_nebenKnoepfe_Natura.glb')
    const Griffbrett = useTexture(snap.items.Griffbrett)
  
    return (
      <>
        <group>
          <mesh geometry={nodes.Loecher_Basseite_nebenKnoepfe_Natura.geometry} material={materials.Holz_Loecher} material-map={Griffbrett}/>
          <mesh geometry={nodes.Loecher_Basseite_nebenKnoepfe_Natura_1.geometry} material={materials.Griffbrett} material-map={Griffbrett}/>
        </group>
      </>
    )
  }
  
  function Oergeli_Loecher_Basseite_nebenKnoepfe(props) {
    const snap = useSnapshot(state)
  
    if(snap.items.Verdeck=="Natura"){
      return (
        <Oergeli_Loecher_Basseite_nebenKnoepfe_Natura />
      )
    }else{
      return (
        <Oergeli_Loecher_Basseite_nebenKnoepfe_Standard />
      )
    }
  }*/
  
  ////////////////////////////////////////////////////////////////////////////////Verdeck_Melodieseite_Natura
  
  
 
  ////////////////////////////////////////////////////////////////////////////////
  
  export default function Oergeli_Loecher(props) {
    const snap = props.snap
  
    return (
      <>
        <Oergeli_Loecher_Melodieseite_vorne snap={snap} />
        <Oergeli_Loecher_Melodieseite_hinten snap={snap} />
        <Oergeli_Loecher_Basseite_hinten snap={snap} />
        <Oergeli_Loecher_Basseite_vorne snap={snap} />
      </>
    )
   
  }
